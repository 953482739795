import { AppState } from '../../helpers/store/AppState';

const selectOrders = (state: AppState) => state.orders;

export const selectOrdersList = (state: AppState) => selectOrders(state).orderResponse.data;

export const selectOrdersLoading = (state: AppState) => selectOrders(state).loading;

export const selectSelectedOrder = (state: AppState) => selectOrders(state).selectedOrder;

export const selectOrdersTotal = (state: AppState) => state.orders.orderResponse.total;

export const selectOrdersFilter = (state: AppState) => state.orders.orderFilter;

export const selectOrderFilter = (state: AppState) => selectOrders(state).orderFilter;
