import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from './App';
import { environment } from './environments/environment';
import reportWebVitals from './reportWebVitals';
import './index.css';

Sentry.init({
  dsn: 'https://9968c1053c17363e75810e826be6b3f0@o1114689.ingest.sentry.io/4506298973487104',
  environment: environment.name,
  enabled: ['production', 'staging'].includes(environment.name),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
