import React from 'react';

import { AppRoute } from '../Route';

export enum CustomerRoutes {
  Customers = '',
  DetailCustomer = ':customerId',
  NewCustomer = 'newCustomer',
}

const customerRoutes: AppRoute[] = [
  {
    path: CustomerRoutes.Customers,
    exact: true,
    authenticated: true,
    component: React.lazy(
      () => import('../../../views/pages/Customers/CustomersList/CustomersList')
    ),
  },
  {
    path: CustomerRoutes.DetailCustomer,
    exact: true,
    authenticated: true,
    component: React.lazy(
      () => import('../../../views/pages/Customers/CustomerDetails/CustomerDetails')
    ),
  },
  {
    path: CustomerRoutes.NewCustomer,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Customers/NewCustomer/NewCustomer')),
  },
];

export default customerRoutes;
