import { AppState } from '../../helpers/store/AppState';

const selectDevices = (state: AppState) => state.devices;

export const selectDevicesList = (state: AppState) => selectDevices(state).deviceResponse.data;

export const selectDevicesLoading = (state: AppState) => selectDevices(state).loading;

export const selectSelectedDevice = (state: AppState) => selectDevices(state).selectedDevice;

export const selectDevicesTotal = (state: AppState) => state.devices.deviceResponse.total;

export const selectDeviceFilter = (state: AppState) => selectDevices(state).deviceFilter;

export const selectDevicesFilter = (state: AppState) => state.devices.deviceFilter;

export const selectOrderDevices = (state: AppState) => selectDevices(state).selectedOrderDevices;

export const selectCustomerDevices = (state: AppState) =>
  selectDevices(state).selectedCustomerDevices;
