import { createActionCreator } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum ToastActionTypes {
  CreateToast = '@@Toast/CREATE',
  CreateSuccessToast = '@@Toast/CREATE_SUCCESS',
  CreateWarnToast = '@@Toast/CREATE_WARN',
  CreateErrorToast = '@@Toast/CREATE_ERROR',
  CreateInfoToast = '@@Toast/CREATE_INFO',
  ClearToast = '@@Toast/CLEAR',
}

/* ACTION MODELS */

/* ACTIONS */
export const toastCreateAction = createActionCreator(ToastActionTypes.CreateToast);
export const toastCreateSuccessAction = createActionCreator(ToastActionTypes.CreateSuccessToast);
export const toastCreateWarnAction = createActionCreator(ToastActionTypes.CreateWarnToast);
export const toastCreateErrorAction = createActionCreator(ToastActionTypes.CreateErrorToast);
export const toastCreateInfoAction = createActionCreator(ToastActionTypes.CreateInfoToast);
export const toastClearAction = createActionCreator(ToastActionTypes.ClearToast);
