import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { UserRoutes } from '../../helpers/route/routes/user-routes';
import { history } from '../../helpers/store/root-reducer';
import { CreateUser, UpdateUser, User, UserFilter, UserResponse } from '../../models/User';
import { GetOrdersActions } from '../orders/actions';
import {
  CreateUserActions,
  DeleteUserActions,
  GetUserActions,
  GetUsersAction,
  SelectUserActions,
  UsersActionTypes,
  usersCreateUserActions,
  usersDeleteUserActions,
  usersGetUserActions,
  usersGetUsersActions,
  usersUpdateUserActions,
} from './actions';
import { api } from './api';
import { selectUsersFilter } from './selectors';
const ITEMS_PER_PAGE = 50;

/* STATE */
export interface UsersState {
  userResponse: UserResponse;
  selectedUser: User | null;
  loading: LoadingStatus;
  userFilter: UserFilter;
}

/* REDUCERS */
const initialState: UsersState = {
  userResponse: {
    limit: 0,
    total: 0,
    skip: 0,
    data: [],
  },
  userFilter: {},
  selectedUser: null,
  loading: LoadingStatus.initial,
};

const userResponse = createReducer(initialState.userResponse, {
  [UsersActionTypes.GetUsers]: {
    [RequestActionTypes.REQUEST]: initialState.userResponse,
    [RequestActionTypes.SUCCESS]: (state: UserResponse, payload: UserResponse) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.userResponse,
  },
});

const selectedUser = createReducer(initialState.selectedUser, {
  [UsersActionTypes.GetUser]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedUser,
  },
  [UsersActionTypes.CreateUser]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedUser,
  },
  [UsersActionTypes.CleanUser]: () => initialState.selectedUser,
  [UsersActionTypes.CleanUsers]: () => initialState.selectedUser,
});

const loading = createLoadingStateReducer(initialState.loading, {
  [UsersActionTypes.CreateUser]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [UsersActionTypes.GetUsers]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [UsersActionTypes.GetUser]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [UsersActionTypes.DeleteUser]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [UsersActionTypes.UpdateUser]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const userFilter = createReducer(initialState.userFilter, {
  [UsersActionTypes.SetFilter]: (state: UserFilter, payload: UserFilter) => payload,
});

export default combineReducers<UsersState>({
  userResponse,
  selectedUser,
  loading,
  userFilter,
});

/* SAGAS */
function* getUsers({ payload }: AppAction<GetUsersAction>) {
  const selectFilter: UserFilter = yield select(selectUsersFilter);
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    payload.limit,
    payload.skip,
    payload.filter === null ? null : selectFilter
  );
  if (resp.ok) {
    yield put(usersGetUsersActions.success(resp.data));
  } else {
    yield put(usersGetUsersActions.failure());
  }
}

function* getUser({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.get, payload);
  if (resp.ok) {
    yield put(usersGetUserActions.success(resp.data));
  } else {
    yield put(usersGetUserActions.failure());
  }
}

function* createUser({ payload }: AppAction<CreateUser>) {
  const resp: ExtendedAxiosResponse = yield call(api.create, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.get, resp.data.id);
    yield put(usersGetUserActions.success(res.data));
    history.push(
      buildRoute([AppRoutes.Users, UserRoutes.UserDetails], {
        userId: resp.data.id,
      })
    );
  } else {
    yield put(usersCreateUserActions.failure());
  }
}

function* deleteUser({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.delete, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, 0, {});
    if (res.ok) {
      yield put(usersGetUsersActions.success(res.data));
    }
  } else {
    yield put(usersDeleteUserActions.failure());
  }
}

function* updateUser({ payload }: AppAction<UpdateUser>) {
  const resp: ExtendedAxiosResponse = yield call(api.update, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.get, resp.data.id);
    if (res.ok) {
      yield put(usersGetUserActions.success(res.data));
    } else {
      yield put(usersGetUserActions.failure());
    }
  } else {
    yield put(usersUpdateUserActions.failure());
  }
}

function* setFilter({ payload }: AppAction<UserFilter>) {
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    ITEMS_PER_PAGE,
    0,
    payload as UserFilter
  );
  if (resp.ok) {
    yield put(usersGetUsersActions.success(resp.data));
  } else {
    yield put(usersGetUsersActions.failure());
  }
}

/* EXPORT */
export function* usersSaga() {
  yield takeLatest(
    createActionType(UsersActionTypes.GetUsers, RequestActionTypes.REQUEST),
    getUsers
  );
  yield takeLatest(createActionType(UsersActionTypes.GetUser, RequestActionTypes.REQUEST), getUser);
  yield takeLatest(
    createActionType(UsersActionTypes.CreateUser, RequestActionTypes.REQUEST),
    createUser
  );
  yield takeLatest(
    createActionType(UsersActionTypes.DeleteUser, RequestActionTypes.REQUEST),
    deleteUser
  );
  yield takeLatest(
    createActionType(UsersActionTypes.UpdateUser, RequestActionTypes.REQUEST),
    updateUser
  );
  yield takeLatest(UsersActionTypes.SetFilter, setFilter);
}
