import React from 'react';
import ReactDOM from 'react-dom';

import { useModal } from '../ModalProvider';

const Modal = () => {
  const { modals } = useModal();

  return ReactDOM.createPortal(
    <>
      {modals.map((modal, i) => (
        <div key={i} className="Modal">
          {modal}
        </div>
      ))}
    </>,
    document.getElementById('modal-root') as Element
  );
};

export default Modal;
