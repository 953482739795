import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { environment } from '../../../environments/environment';

interface Props {
  children: string | string[];
  fallBack?: string | string[];
  params?: Record<string, any>;
  components?: Record<string, React.ReactNode>;
  hideIfNotFound?: boolean;
}

export const Translate = ({ children, fallBack, params, components, hideIfNotFound }: Props) => {
  const translateKey = typeof children === 'string' ? children : children.join('');
  const fallBackKey = !fallBack || typeof fallBack === 'string' ? fallBack : fallBack.join('');
  const { t } = useTranslation();

  const translation = t(translateKey, params);

  const getComponent = (key: string, baseKey?: string) => (
    <span data-key={key} data-base={baseKey}>
      <Trans t={t} i18nKey={key} values={params} components={components} />
    </span>
  );

  if ((translateKey === translation || ['', ' '].includes(translation)) && fallBackKey) {
    return getComponent(fallBackKey, translateKey);
  }

  if (
    (translateKey === translation && (!environment.i18n.showEmptyKeys || hideIfNotFound)) ||
    ['', ' '].includes(translation)
  ) {
    return <></>;
  }

  return getComponent(translateKey);
};
