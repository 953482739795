/* eslint-disable camelcase */
import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { CustomerFilter, CustomerValue, CustomerPlace } from '../../models/Customer';

export const api = {
  create: (customer: CustomerValue): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/customer', customer),
  createPlace: (id: string, place: Partial<CustomerPlace>): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/customer/${id}/place`, place),
  createOrderPlace: (id: string, placeId: any): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/order/${id}/place`, placeId),
  get: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/customer/${id}`),
  getAll: ($limit: number, $skip: number, filter: CustomerFilter): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/customer', {
      params: {
        $limit,
        $skip,
        '$sort[createdAt]': filter?.sort ? filter?.sort : -1,
        'company_name': filter?.companyName,
        'contact': filter?.contact,
        'ico': filter?.ico,
        'residence': filter?.residence,
      },
    }),
  getPlaces: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/customer/${id}/place`),
  update: (id: string, updatedCustomer: CustomerValue): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/customer/${id}`, updatedCustomer),
  updatePlace: (
    customerId: string,
    placeId: string,
    place: Partial<CustomerPlace>
  ): Promise<ExtendedAxiosResponse<any>> =>
    apiClient.patch(`/customer/${customerId}/place/${placeId}`, place),
  delete: (id: string): Promise<ExtendedAxiosResponse> => apiClient.delete(`/customer/${id}`),
  deletePlace: (place: CustomerPlace): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/customer/${place.customerId}/place/${place.id}`),
};
