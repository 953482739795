import React from 'react';

import { AppRoute } from '../Route';

export enum DeviceRoutes {
  Devices = '',
  DeviceDetails = ':deviceId',
  NewDevice = 'newDevice',
  NewDeviceByTemplate = 'newDevice/:templateId',
}

const deviceRoutes: AppRoute[] = [
  {
    path: DeviceRoutes.Devices,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Devices/DevicesList/DevicesList')),
  },
  {
    path: DeviceRoutes.NewDevice,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Devices/NewDevice/NewDevice')),
  },
  {
    path: DeviceRoutes.NewDeviceByTemplate,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Devices/NewDevice/NewDevice')),
  },
  {
    path: DeviceRoutes.DeviceDetails,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Devices/DeviceDetails/DeviceDetails')),
  },
];

export default deviceRoutes;
