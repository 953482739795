import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../../saga/auth/ducks';
import { customersSaga } from '../../saga/customers/ducks';
import { departuresSaga } from '../../saga/departures/ducks';
import { devicesSaga } from '../../saga/devices/ducks';
import { documentsSaga } from '../../saga/documents/ducks';
import { ordersSaga } from '../../saga/orders/ducks';
import { statsSaga } from '../../saga/stats/ducks';
import { toastSaga } from '../../saga/toast/ducks';
import { usersSaga } from '../../saga/users/ducks';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(toastSaga),
    fork(ordersSaga),
    fork(customersSaga),
    fork(devicesSaga),
    fork(departuresSaga),
    fork(usersSaga),
    fork(documentsSaga),
    fork(statsSaga),
  ]);
}
