/* eslint-disable camelcase */
import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { Device, CreateDevice, DeviceFilter } from '../../models/Device';
import { OrderDeviceActions, UpdateCheckingActions } from './actions';

export const api = {
  create: (device: CreateDevice): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/device', device),
  get: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/device/${id}`),
  getOrder: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/order/${id}`),
  getAll: ($limit: number, $skip: number, filter: DeviceFilter): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/device', {
      params: {
        $limit,
        $skip,
        '$sort[checkingDate]': filter?.sort ? filter?.sort : -1,
        'company_name': filter?.companyName,
        'label': filter?.label,
        'manufacturer': filter?.manufacturer,
        'serial_number': filter?.serialNumber,
        'customerId': filter?.customerId,
      },
    }),
  getOrderDevices: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/order/${id}/device`),
  getOrderPlaces: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/order/${id}/place`),
  getCustomerDevices: (customerId: string): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/device', { params: { customerId } }),
  update: (id: string, updatedDevice: Device): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/device/${id}`, updatedDevice),
  updateChecking: (
    id: string,
    updatedDevice: UpdateCheckingActions
  ): Promise<ExtendedAxiosResponse> => apiClient.patch('/device/update-checking/', updatedDevice),
  delete: (id: string): Promise<ExtendedAxiosResponse> => apiClient.delete(`/device/${id}`),
  deleteOrderDevice: (deletedDevice: OrderDeviceActions): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/order/${deletedDevice.orderId}/device/${deletedDevice.id}`),
  createOrderDevice: (order: string, deviceId: any): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/order/${order}/device`, deviceId),
  deleteCustomerDevice: (id: string, device: any): Promise<ExtendedAxiosResponse> =>
    apiClient.patch(`/device/${id}`, device),
};
