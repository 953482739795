import { toast } from 'react-toastify';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { CustomerRoutes } from '../../helpers/route/routes/customer-routes';
import { OrderRoutes } from '../../helpers/route/routes/order-routes';
import { history } from '../../helpers/store/root-reducer';
import {
  Customer,
  CustomerFilter,
  CustomerPlace,
  CustomerResponse,
  CustomerValue,
} from '../../models/Customer';
import {
  CreateCustomerActions,
  CustomersActionTypes,
  customersCreateCustomerActions,
  customersCreatePlaceActions,
  customersDeleteCustomerActions,
  customersDeletePlaceActions,
  customersGetCustomerActions,
  customersGetCustomersActions,
  customersUpdateCustomerActions,
  DeleteCustomerActions,
  GetCustomerActions,
  GetCustomersActions,
  SelectCustomerActions,
  UpdateCreationCustomerActions,
} from './actions';
import { api } from './api';
import { selectCustomersFilter } from './selectors';
const ITEMS_PER_PAGE = 50;

/* STATE */
export interface CustomersState {
  customerResponse: CustomerResponse;
  selectedCustomer: Customer | null;
  loading: LoadingStatus;
  customerFilter: CustomerFilter;
}

/* REDUCERS */
const initialState: CustomersState = {
  customerResponse: {
    limit: 0,
    total: 0,
    skip: 0,
    data: [],
  },
  customerFilter: {},
  selectedCustomer: null,
  loading: LoadingStatus.initial,
};

const customerResponse = createReducer(initialState.customerResponse, {
  [CustomersActionTypes.GetCustomers]: {
    [RequestActionTypes.REQUEST]: initialState.customerResponse,
    [RequestActionTypes.SUCCESS]: (state: CustomerResponse, payload: CustomerResponse) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.customerResponse,
  },
});

const selectedCustomer = createReducer(initialState.selectedCustomer, {
  [CustomersActionTypes.GetCustomer]: {
    [RequestActionTypes.SUCCESS]: (state: Customer | null, payload: Customer) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedCustomer,
  },
  [CustomersActionTypes.CreateCustomer]: {
    [RequestActionTypes.SUCCESS]: (state: Customer | null, payload: Customer) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedCustomer,
  },
  [CustomersActionTypes.CleanCustomer]: () => initialState.selectedCustomer,
});

const loading = createLoadingStateReducer(initialState.loading, {
  [CustomersActionTypes.CreateCustomer]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [CustomersActionTypes.GetCustomers]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [CustomersActionTypes.GetCustomer]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [CustomersActionTypes.DeleteCustomer]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [CustomersActionTypes.UpdateCustomer]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const customerFilter = createReducer(initialState.customerFilter, {
  [CustomersActionTypes.SetFilter]: (state: CustomerFilter, payload: CustomerFilter) => payload,
});

export default combineReducers<CustomersState>({
  customerResponse,
  selectedCustomer,
  loading,
  customerFilter,
});

/* SAGAS */
function* getCustomers({ payload }: AppAction<GetCustomersActions>) {
  const selectFilter: CustomerFilter = yield select(selectCustomersFilter);
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    payload.limit,
    payload.skip,
    selectFilter
  );
  if (resp.ok) {
    yield put(customersGetCustomersActions.success(resp.data));
  } else {
    yield put(customersGetCustomersActions.failure());
  }
}

function* getCustomer({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.get, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.getPlaces, payload);
    if (res.ok) {
      yield put(
        customersGetCustomerActions.success({
          ...resp.data,
          places: res.data.data,
        })
      );
    }
  } else {
    yield put(customersGetCustomerActions.failure());
  }
}

function* createCustomer({ payload }: AppAction<CustomerValue>) {
  const t = payload.redirect;
  delete payload.redirect;
  const resp: ExtendedAxiosResponse = yield call(api.create, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.get, resp.data.id);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(api.getPlaces, resp.data.id);
      if (re.ok) {
        yield put(
          customersCreateCustomerActions.success({
            ...res.data,
            places: re.data.data,
          })
        );
      }
    }
    if (t) {
      history.push(
        buildRoute([AppRoutes.Customers, CustomerRoutes.DetailCustomer], {
          customerId: resp.data.id,
        })
      );
    }
  } else {
    yield put(customersCreateCustomerActions.failure());
  }
}

function* deleteCustomer({ payload }: AppAction<DeleteCustomerActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.delete, payload.id);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, 0, {});
    if (res.ok) {
      yield put(customersGetCustomersActions.success(res.data));
    }
  } else {
    yield put(customersDeleteCustomerActions.failure());
  }
}

function* updateCustomer({ payload }: AppAction<Customer>) {
  const { id, ...data } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.update, id, data as CustomerValue);
  if (resp.ok) {
    yield put(customersUpdateCustomerActions.success(resp.data));
  } else {
    yield put(customersUpdateCustomerActions.failure());
  }
}

function* createPlace({ payload }: AppAction<CustomerPlace>) {
  const { customerId, ...requestBody } = payload;
  const filteredRequestBody = Object.fromEntries(
    Object.entries(requestBody).filter(([_, value]) => value !== '')
  );
  const resp: ExtendedAxiosResponse = yield call(api.createPlace, customerId, filteredRequestBody);
  if (resp.ok) {
    toast.success('Uložené');
    const res: ExtendedAxiosResponse = yield call(api.get, payload.customerId);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(api.getPlaces, payload.customerId);
      if (re.ok) {
        yield put(
          customersGetCustomerActions.success({
            ...res.data,
            places: re.data.data,
          })
        );
      }
    }
    if (payload?.id) {
      const re: ExtendedAxiosResponse = yield call(api.createOrderPlace, payload.id, {
        placeId: resp.data.id,
      });
    }
  } else {
    toast.error('Vyskytla sa chyba');
    yield put(customersCreatePlaceActions.failure());
  }
}

function* updatePlace({ payload }: AppAction<CustomerPlace>) {
  const { customerId, id, ...requestBody } = payload;
  const filteredRequestBody: Record<string, any> = {};
  Object.entries(requestBody).map(([key, value]) => {
    const updatedValue = value === '' ? null : value;
    filteredRequestBody[key] = updatedValue;
  });

  const resp: ExtendedAxiosResponse = yield call(
    api.updatePlace,
    customerId as string,
    id as string,
    filteredRequestBody
  );
  if (resp.ok) {
    yield put(customersUpdateCustomerActions.success(resp.data));
    toast.success('Uložené');
    const res: ExtendedAxiosResponse = yield call(api.get, payload.customerId);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(api.getPlaces, payload.customerId);
      if (re.ok) {
        yield put(
          customersGetCustomerActions.success({
            ...res.data,
            places: re.data.data,
          })
        );
      }
    }
  } else {
    toast.error('Vyskytla sa chyba');
    yield put(customersCreatePlaceActions.failure());
  }
}

function* deletePlace({ payload }: AppAction<CustomerPlace>) {
  const resp: ExtendedAxiosResponse = yield call(api.deletePlace, payload);
  if (resp.ok) {
    toast.success('Uložené');
    const res: ExtendedAxiosResponse = yield call(api.get, payload.customerId);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(api.getPlaces, payload.customerId);
      if (re.ok) {
        yield put(
          customersGetCustomerActions.success({
            ...res.data,
            places: re.data.data,
          })
        );
      }
    }
  } else {
    toast.error('Vyskytla sa chyba');
    yield put(customersDeletePlaceActions.failure());
  }
}

function* setFilter({ payload }: AppAction<GetCustomersActions>) {
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    ITEMS_PER_PAGE,
    0,
    payload as CustomerFilter
  );
  if (resp.ok) {
    yield put(customersGetCustomersActions.success(resp.data));
  } else {
    yield put(customersGetCustomersActions.failure());
  }
}

/* EXPORT */
export function* customersSaga() {
  yield takeLatest(
    createActionType(CustomersActionTypes.GetCustomers, RequestActionTypes.REQUEST),
    getCustomers
  );
  yield takeLatest(
    createActionType(CustomersActionTypes.GetCustomer, RequestActionTypes.REQUEST),
    getCustomer
  );
  yield takeLatest(
    createActionType(CustomersActionTypes.CreateCustomer, RequestActionTypes.REQUEST),
    createCustomer
  );
  yield takeLatest(
    createActionType(CustomersActionTypes.DeleteCustomer, RequestActionTypes.REQUEST),
    deleteCustomer
  );
  yield takeLatest(
    createActionType(CustomersActionTypes.UpdateCustomer, RequestActionTypes.REQUEST),
    updateCustomer
  );
  yield takeLatest(
    createActionType(CustomersActionTypes.CreatePlace, RequestActionTypes.REQUEST),
    createPlace
  );
  yield takeLatest(
    createActionType(CustomersActionTypes.UpdatePlace, RequestActionTypes.REQUEST),
    updatePlace
  );
  yield takeLatest(
    createActionType(CustomersActionTypes.DeletePlace, RequestActionTypes.REQUEST),
    deletePlace
  );
  yield takeLatest(CustomersActionTypes.SetFilter, setFilter);
}
