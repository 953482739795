import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import auth from '../../saga/auth/ducks';
import customers from '../../saga/customers/ducks';
import departures from '../../saga/departures/ducks';
import devices from '../../saga/devices/ducks';
import documents from '../../saga/documents/ducks';
import layout from '../../saga/layout/ducks';
import orders from '../../saga/orders/ducks';
import stats from '../../saga/stats/ducks';
import toast from '../../saga/toast/ducks';
import users from '../../saga/users/ducks';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  auth,
  toast,
  orders,
  customers,
  devices,
  layout,
  departures,
  users,
  documents,
  stats,
});

export default rootReducer;
