/* eslint-disable camelcase */
import { apiClient } from '../../helpers/api-client';
import {
  DeviceTypeSalesStatFilter,
  OrderStateStatFilter,
  OrderTypeSalesStatFilter,
  OrderTypeStatFilter,
  StatFilter,
} from '../../models/Stats';

export const api = {
  getOrderStateStat: (filter: OrderStateStatFilter) =>
    apiClient.get('order/stat/state', {
      params: {
        departmentType: filter.departmentType,
        department: filter.departments,
        date_from: filter.dateFrom,
        date_to: filter.dateTo,
      },
    }),
  getOrderTypeStat: (filter: OrderTypeStatFilter) =>
    apiClient.get('order/stat/type', {
      params: {
        departmentType: filter.departmentType,
        department: filter.departments,
        date_from: filter.dateFrom,
        date_to: filter.dateTo,
      },
    }),
  getOrderSellerStat: (filter: StatFilter) =>
    apiClient.get('order/stat/seller', {
      params: {
        date_from: filter.dateFrom,
        date_to: filter.dateTo,
      },
    }),
  getDepartmentSalesStat: (filter: StatFilter) =>
    apiClient.get('order/stat/department-sales', {
      params: {
        date_from: filter.dateFrom,
        date_to: filter.dateTo,
      },
    }),
  getOrderTypeSalesStat: (filter: OrderTypeSalesStatFilter) =>
    apiClient.get('order/stat/type-sales', {
      params: {
        departmentType: filter.departmentType,
        departmentResidence: filter.departmentResidence,
        date_from: filter.dateFrom,
        date_to: filter.dateTo,
      },
    }),
  getDeviceTypeSalesStat: (filter: DeviceTypeSalesStatFilter) =>
    apiClient.get('device/stat/type-sales', {
      params: {
        departmentResidence: filter.departmentResidence,
        date_from: filter.dateFrom,
        date_to: filter.dateTo,
      },
    }),
};
