import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { UpdateUser, User, UserFilter, UserValue } from '../../models/User';

export const api = {
  create: (user: UserValue): Promise<ExtendedAxiosResponse> => apiClient.post('/user', user),
  get: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/user/${id}`),
  getAll: (
    $limit: number,
    $skip: number,
    filter: UserFilter | null
  ): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/user', {
      params: {
        $limit,
        $skip,
        '$sort[name]': filter?.sort ? filter?.sort : -1,
        'name': filter?.name,
        'email': filter?.email,
        'role': filter?.role,
      },
    }),
  update: (updatedUser: UpdateUser): Promise<ExtendedAxiosResponse> => {
    const { id, ...user } = updatedUser;
    return apiClient.patch(`/user/${updatedUser.id}`, user);
  },
  delete: (id: string): Promise<ExtendedAxiosResponse> => apiClient.delete(`/user/${id}`),
};
