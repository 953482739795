/* eslint-disable camelcase */
import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { Document, DocumentFilter, SendDocumentEmail } from '../../models/Document';

export const api = {
  create: (document: FormData): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/document', document),
  get: (id: string): Promise<ArrayBuffer> =>
    apiClient.get(`/document/${id}`, { responseType: 'arraybuffer' }),
  getLastDocument: (internalNumberPrefix: string) =>
    apiClient.get('document/last-document', {
      params: {
        internalNumber: internalNumberPrefix,
      },
    }),
  getOrder: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/order/${id}`),
  getOrderPlaces: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/order/${id}/place`),
  getDeparture: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/departure/${id}`),
  getDeparturePlaces: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/departure/${id}/place`),
  getDevice: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/device/${id}`),
  getAll: ($limit: number, $skip: number, filter: DocumentFilter): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/document', {
      params: {
        $limit,
        $skip,
        documentableId: filter?.documentableId,
        documentableType: filter?.documentableType,
        internalNumber: filter?.internalNumber,
        description: filter?.description,
        confidential: filter?.confidential,
      },
    }),
  sendEmail: (payload: SendDocumentEmail) => apiClient.post('document/email', payload),
  update: (id: string, document: Document): Promise<ExtendedAxiosResponse> =>
    apiClient.patch(`/document/${id}`, document),
  delete: (id: string): Promise<ExtendedAxiosResponse> => apiClient.delete(`/document/${id}`),
};
