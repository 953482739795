import jszip from 'jszip';

import { toast } from 'react-toastify';

import { Document } from '../models/Document';
import { OrderDepartment } from '../models/Order';
import { User } from '../models/User';
import { documentURL } from './api-client';

export interface Option {
  label: string;
  value: string;
}

export const createOption = (label: string): Option => ({
  label,
  value: label,
});

export const createOptions = (label: string, value: string): Option => ({
  label,
  value,
});

export const getOptionValue = (options: Option[]) => options.map((opt) => opt.value);

export const agreementOption = [
  {
    label: 'Áno',
    value: 'true',
  },
  {
    label: 'Nie',
    value: 'false',
  },
];

export const updateObj = <T, K extends keyof T>(obj: T, key: K, value: T[K]) => {
  return {
    ...obj,
    [key]: value,
  };
};

export enum AllFilter {
  ALL = 'Všetky',
}

interface Entity {
  [key: string]: any;
}

interface Item {
  id: string;
  [key: string]: any;
}

export const deleteItem = <T extends Entity, K extends keyof T>(obj: T, key: K, id: string) => {
  if (Array.isArray(obj[key])) {
    const updatedItems = obj[key].filter((item: Item) => item.id !== id);

    return {
      ...obj,
      [key]: updatedItems,
    };
  }

  return obj;
};

export const parseDepartmentFromPermission = (permission: string) => {
  const [, , departmentCode] = permission.split('.');
  return Object.values(OrderDepartment).includes(departmentCode as OrderDepartment)
    ? departmentCode
    : null;
};

export const hasOrderPermission = (
  user: User | null,
  permissionType: string,
  department?: OrderDepartment
) => {
  return (
    user?.permissions?.some((permission) =>
      permission.startsWith(`order.${permissionType}${department ? `.${department}` : ''}`)
    ) ?? false
  );
};

export const isMontageDepartment = (department: OrderDepartment) => {
  return [OrderDepartment.bm, OrderDepartment.zm].includes(department);
};

export const isServiceDepartment = (department: OrderDepartment) => {
  return [OrderDepartment.bs, OrderDepartment.zs].includes(department);
};

export const zipFile = async (file: File) => {
  const zip = new jszip();
  zip.file(file.name, await file.arrayBuffer());

  return await zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 9,
    },
  });
};

export const handleDocumentInteraction = async (
  doc: Document,
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  forceDownload?: boolean
) => {
  const fileExtension = doc.name?.split('.').pop()?.toLowerCase() ?? '';
  const needsSpecialDownloadHandling = ['skp', 'dwg'].includes(fileExtension);

  if (needsSpecialDownloadHandling || forceDownload) {
    event.preventDefault();

    try {
      const response = await fetch(`${documentURL}${doc.id}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Could not download document.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', doc.name ?? '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Nepodarilo sa stiahnuť dokument.');
    }
  }
};
