import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';

export const api = {
  login: (email: string, password: string): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/auth', {
      email,
      password,
      strategy: 'local',
    }),
  logout: (): Promise<ExtendedAxiosResponse> => apiClient.delete('/auth', {}),
  clearSessions: (): Promise<ExtendedAxiosResponse> => apiClient.post('/auth/clear-sessions'),
  forgottenPassword: (email: string): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/auth/forgotten', {
      email,
    }),
  resetPassword: (
    password: string,
    confirmPassword: string
  ): Promise<ExtendedAxiosResponse> => //Request change password with forgotten password token
    apiClient.put('/auth/reset-password', {
      password,
      confirmPassword,
    }),
  changePassword: (
    oldPassword: string,
    password: string,
    confirmPassword: string
  ): Promise<ExtendedAxiosResponse> => //Request change password
    apiClient.post('/auth/change-password', {
      oldPassword,
      password,
      confirmPassword,
    }),
  getProfile: (): Promise<ExtendedAxiosResponse> => apiClient.get('/auth/profile'),
};
