import { toast } from 'react-toastify';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { DeparturePlace } from '../../models/Departure';
import {
  Document,
  DocumentFilter,
  DocumentResponse,
  DocumentValue,
  SendDocumentEmail,
} from '../../models/Document';
import { OrderPlace } from '../../models/Order';
import { departuresGetDepartureActions } from '../departures/actions';
import { devicesGetDeviceActions } from '../devices/actions';
import { ordersGetOrderActions } from '../orders/actions';
import {
  DocumentsActionTypes,
  documentsCreateDocumentActions,
  documentsGetDocumentsActions,
  documentsGetDocumentActions,
  documentsUpdateDocumentActions,
  documentsDeleteDocumentActions,
  GetDocumentsActions,
  GetDocumentActions,
  GetLastDocumentActions,
  documentsGetLastDocumentActions,
  documentsSendEmailActions,
} from './actions';
import { api } from './api';
import { selectDocumentsFilter } from './selectors';
const ITEMS_PER_PAGE = 50;

/* STATE */
export interface DocumentsState {
  documentResponse: DocumentResponse;
  selectedDocument: Document | null;
  loading: LoadingStatus;
  documentFilter: DocumentFilter;
  lastDocument: Document[];
}

/* REDUCERS */
const initialState: DocumentsState = {
  documentResponse: {
    limit: 0,
    total: 0,
    skip: 0,
    data: [],
  },
  documentFilter: {},
  selectedDocument: null,
  loading: LoadingStatus.initial,
  lastDocument: [],
};

const documentResponse = createReducer(initialState.documentResponse, {
  [DocumentsActionTypes.GetDocuments]: {
    [RequestActionTypes.REQUEST]: initialState.documentResponse,
    [RequestActionTypes.SUCCESS]: (state: DocumentResponse, payload: DocumentResponse) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.documentResponse,
  },
});

const lastDocument = createReducer(initialState.lastDocument, {
  [DocumentsActionTypes.GetLastDocument]: {
    [RequestActionTypes.REQUEST]: initialState.lastDocument,
    [RequestActionTypes.SUCCESS]: (state: DocumentResponse, payload: DocumentResponse) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.lastDocument,
  },
  [DocumentsActionTypes.ClearGetLastDocument]: () => initialState.lastDocument,
});

const selectedDocument = createReducer(initialState.selectedDocument, {
  [DocumentsActionTypes.GetDocument]: {
    [RequestActionTypes.SUCCESS]: (state: Document | null, payload: Document) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedDocument,
  },
  [DocumentsActionTypes.CreateDocument]: {
    [RequestActionTypes.SUCCESS]: (state: Document | null, payload: Document) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedDocument,
  },
  [DocumentsActionTypes.UpdateDocument]: {
    [RequestActionTypes.SUCCESS]: (state: Document | null, payload: Document) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedDocument,
  },
  [DocumentsActionTypes.CleanDocument]: () => initialState.selectedDocument,
});

const loading = createLoadingStateReducer(initialState.loading, {
  [DocumentsActionTypes.CreateDocument]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DocumentsActionTypes.GetDocuments]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DocumentsActionTypes.GetDocument]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DocumentsActionTypes.GetLastDocument]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DocumentsActionTypes.DeleteDocument]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DocumentsActionTypes.UpdateDocument]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DocumentsActionTypes.SendEmail]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const documentFilter = createReducer(initialState.documentFilter, {
  [DocumentsActionTypes.SetFilter]: (state: DocumentFilter, payload: DocumentFilter) => payload,
});

export default combineReducers<DocumentsState>({
  documentResponse,
  selectedDocument,
  loading,
  documentFilter,
  lastDocument,
});

/* SAGAS */
function* getDocuments({ payload }: AppAction<GetDocumentsActions>) {
  const selectFilter: DocumentFilter = yield select(selectDocumentsFilter);
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    payload.limit,
    payload.skip,
    selectFilter
  );
  if (resp.ok) {
    yield put(documentsGetDocumentsActions.success(resp.data));
  } else {
    yield put(documentsGetDocumentsActions.failure());
  }
}

function* getDocument({ payload }: AppAction<GetDocumentActions>) {
  const { id, ...data } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.get, id);
  if (resp.ok) {
    yield put(documentsGetDocumentActions.success(resp.data));
  } else {
    yield put(documentsGetDocumentActions.failure());
  }
}

function* getLastDocument({ payload }: AppAction<GetLastDocumentActions>) {
  const { internalNumberPrefix } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.getLastDocument, internalNumberPrefix);
  if (resp.ok) {
    yield put(documentsGetLastDocumentActions.success(resp.data));
    toast.success('Získavanie posledného čísla dokumentu úspešné');
  } else {
    yield put(documentsGetLastDocumentActions.failure());
    toast.error('Chyba pri získavaní posledného čísla dokumentu');
  }
}

function* createDocument({ payload }: AppAction<DocumentValue>) {
  const form = new FormData();
  form.append('file', payload.file!);
  form.append('fileName', payload.fileName!);
  form.append('fileType', payload.fileType!);
  form.append('documentableType', payload.documentableType!);
  form.append('documentableId', payload.documentableId!);
  if (payload.internalNumber) {
    form.append('internalNumber', payload.internalNumber);
  }
  if (payload.description) {
    form.append('description', payload.description);
  }
  if (payload.confidential) {
    form.append('confidential', payload.confidential.toString());
  }
  const uploading = toast.loading('Súbor sa nahráva');
  const resp: ExtendedAxiosResponse = yield call(api.create, form);
  if (resp.ok) {
    toast.update(uploading, {
      render: 'Súbor bol úspešne nahraný',
      type: 'success',
      isLoading: false,
      autoClose: 3000,
    });
    yield updateDetails(resp);
    yield put(documentsCreateDocumentActions.success(resp.data));
  } else {
    if (
      resp.data.errors &&
      resp.data.errors.length &&
      resp.data.errors[0].message === 'internalNumber must be unique'
    ) {
      toast.update(uploading, {
        render: 'Pri nahrávaní súboru sa vyskytla chyba: Číslo súboru musí byť unikátne',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(uploading, {
        render: `Pri nahrávaní súboru sa vyskytla chyba${
          resp.data.errors && resp.data.errors.length ? `: ${resp.data.errors[0].message}` : ''
        }`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
    yield put(documentsCreateDocumentActions.failure());
  }
}

function* deleteDocument({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.delete, payload);
  if (resp.ok) {
    yield updateDetails(resp);
    yield put(documentsDeleteDocumentActions.success(resp.data));
  } else {
    yield put(documentsDeleteDocumentActions.failure());
  }
}

function* updateDocument({ payload }: AppAction<Document>) {
  const { id, ...data } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.update, id!, data as Document);
  if (resp.ok) {
    yield updateDetails(resp);
    yield put(documentsUpdateDocumentActions.success(resp.data));
  } else {
    yield put(documentsUpdateDocumentActions.failure());
  }
}

function* setFilter({ payload }: AppAction<GetDocumentsActions>) {
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    ITEMS_PER_PAGE,
    0,
    payload as DocumentFilter
  );
  if (resp.ok) {
    yield put(documentsGetDocumentsActions.success(resp.data));
  } else {
    yield put(documentsGetDocumentsActions.failure());
  }
}

function* updateDetails(resp: any) {
  if (resp.data.documentableType == 'order') {
    const res: ExtendedAxiosResponse = yield call(api.getOrder, resp.data.documentableId);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(api.getOrderPlaces, resp.data.documentableId);
      if (re.ok) {
        const places = re.data.data
          .map((entry: { place: OrderPlace }) => entry.place)
          .filter((place: OrderPlace) => place);

        yield put(
          ordersGetOrderActions.success({
            ...res.data,
            places: places,
          })
        );
      }
    }
  }
  if (resp.data.documentableType == 'departure') {
    const res: ExtendedAxiosResponse = yield call(api.getDeparture, resp.data.documentableId);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(
        api.getDeparturePlaces,
        resp.data.documentableId
      );
      if (re.ok) {
        const places = re.data.data
          .map((entry: { place: DeparturePlace }) => entry.place)
          .filter((place: DeparturePlace) => place);

        yield put(
          departuresGetDepartureActions.success({
            ...res.data,
            places,
          })
        );
      }
    }
  }
  if (resp.data.documentableType == 'device') {
    const res: ExtendedAxiosResponse = yield call(api.getDevice, resp.data.documentableId);
    if (res.ok) {
      yield put(devicesGetDeviceActions.success(res.data));
    }
  }
}

function* sendEmail({ payload }: AppAction<SendDocumentEmail>) {
  const uploading = toast.loading('Email sa odosiela');
  const resp: ExtendedAxiosResponse = yield call(api.sendEmail, payload);
  if (resp.ok) {
    toast.update(uploading, {
      render: 'Email bol úspešne odoslaný',
      type: 'success',
      isLoading: false,
      autoClose: 3000,
    });
    yield put(documentsSendEmailActions.success(resp.data));
  } else {
    toast.update(uploading, {
      render: `Pri odosielaní emailu sa vyskytla chyba: ${resp.data.errors[0].message}`,
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    });

    yield put(documentsSendEmailActions.failure());
  }
}

/* EXPORT */
export function* documentsSaga() {
  yield takeLatest(
    createActionType(DocumentsActionTypes.GetDocuments, RequestActionTypes.REQUEST),
    getDocuments
  );
  yield takeLatest(
    createActionType(DocumentsActionTypes.GetDocument, RequestActionTypes.REQUEST),
    getDocument
  );
  yield takeLatest(
    createActionType(DocumentsActionTypes.GetLastDocument, RequestActionTypes.REQUEST),
    getLastDocument
  );
  yield takeLatest(
    createActionType(DocumentsActionTypes.CreateDocument, RequestActionTypes.REQUEST),
    createDocument
  );
  yield takeLatest(
    createActionType(DocumentsActionTypes.DeleteDocument, RequestActionTypes.REQUEST),
    deleteDocument
  );
  yield takeLatest(
    createActionType(DocumentsActionTypes.UpdateDocument, RequestActionTypes.REQUEST),
    updateDocument
  );
  yield takeLatest(DocumentsActionTypes.SetFilter, setFilter);
  yield takeLatest(
    createActionType(DocumentsActionTypes.SendEmail, RequestActionTypes.REQUEST),
    sendEmail
  );
}
