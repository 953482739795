import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { CImage, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';

import { buildRoute } from '../../../helpers/route/route-builder';
import { AppRoutes } from '../../../helpers/route/routes/app-routes';
import { AuthRoutes } from '../../../helpers/route/routes/auth-routes';
import { StatRoutes } from '../../../helpers/route/routes/stat-routes';
import { hasOrderPermission } from '../../../helpers/utils';
import Logo from '../../../resources/images/menton-logo.png';
import { authLogoutActions } from '../../../saga/auth/actions';
import { selectLoggedUser } from '../../../saga/auth/selectors';
import { layoutSidebarShowActions } from '../../../saga/layout/actions';
import { selectSidebarShow } from '../../../saga/layout/selectors';

interface NavLinkProps {
  isActive: boolean;
}

const Sidebar = () => {
  const sidebarShow = useSelector(selectSidebarShow);
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedUser);
  const location = useLocation();
  const [showBackground, setShowBackground] = useState('hideBackground');
  const sidebarToggle = () => {
    dispatch(layoutSidebarShowActions());
  };

  const logOutHandle = () => {
    dispatch(authLogoutActions.request());
  };

  return (
    <>
      <CSidebar
        className="Sidebar print"
        position="fixed"
        unfoldable={false}
        overlaid={window.innerWidth < 1250}
        visible={window.innerWidth < 1250 ? sidebarShow : true}
        onShow={() => {
          setShowBackground('showBackground');
        }}
        onHide={() => {
          setShowBackground('hideBackground');
        }}
      >
        <CSidebarBrand className="d-none d-md-flex bg-white mt-2">
          <CImage src={Logo} alt="Menton logo" className="Sidebar__logo" />
        </CSidebarBrand>
        <CSidebarNav className="mt-3">
          <>
            {(user?.role.toString() === 'ADMIN' || user?.permissions?.includes('stats')) && (
              <NavLink
                onClick={sidebarToggle}
                to={buildRoute([AppRoutes.Stats, StatRoutes.OrderState])}
                className={`Sidebar__nav-link  ${
                  location.pathname.startsWith('/stats/') && 'Sidebar__nav-link__active'
                }`}
              >
                <i className="fa-solid fa-chart-line fa-1x Sidebar__nav-link__icon me-3"></i>
                Štatistiky
              </NavLink>
            )}
            {hasOrderPermission(user, 'read') && (
              <NavLink
                onClick={sidebarToggle}
                to={buildRoute(AppRoutes.Orders)}
                className={({ isActive }: NavLinkProps) =>
                  `Sidebar__nav-link  ${isActive && 'Sidebar__nav-link__active'}`
                }
              >
                <i className="fa-solid fa-box fa-1x Sidebar__nav-link__icon me-3"></i>
                Zákazky
              </NavLink>
            )}
            {user?.permissions?.includes('customer.create') && (
              <NavLink
                onClick={sidebarToggle}
                to={buildRoute(AppRoutes.Customers)}
                className={({ isActive }: NavLinkProps) =>
                  `Sidebar__nav-link  ${isActive && 'Sidebar__nav-link__active'}`
                }
              >
                <i className="fa-solid fa-users fa-1x Sidebar__nav-link__icon me-3"></i>
                Zákazníci
              </NavLink>
            )}
            {user?.permissions?.includes('departure.read') && (
              <NavLink
                onClick={sidebarToggle}
                to={buildRoute(AppRoutes.Departures)}
                className={({ isActive }: NavLinkProps) =>
                  `Sidebar__nav-link  ${isActive && 'Sidebar__nav-link__active'}`
                }
              >
                <i className="fa-solid fa-road fa-1x Sidebar__nav-link__icon me-3"></i>
                Naplánované výjazdy
              </NavLink>
            )}
            {user?.permissions?.includes('device.read') && (
              <NavLink
                onClick={sidebarToggle}
                to={buildRoute(AppRoutes.Devices)}
                className={({ isActive }: NavLinkProps) =>
                  ` Sidebar__nav-link  ${isActive && 'Sidebar__nav-link__active'}`
                }
              >
                <i className="fa-solid fa-dungeon fa-1x Sidebar__nav-link__icon me-3"></i>
                Zariadenia
              </NavLink>
            )}
            {user?.role.toString() == 'ADMIN' && (
              <NavLink
                onClick={sidebarToggle}
                to={buildRoute(AppRoutes.Users)}
                className={({ isActive }: NavLinkProps) =>
                  `Sidebar__nav-link  ${isActive && 'Sidebar__nav-link__active'}`
                }
              >
                <i className="fa-solid fa-user-group fa-1x Sidebar__nav-link__icon me-3"></i>
                Používatelia
              </NavLink>
            )}
          </>
          <div className="nav-end">
            <NavLink
              to={buildRoute([AppRoutes.Auth, AuthRoutes.Login])}
              onClick={logOutHandle}
              className={'Sidebar__nav-link nav-botton '}
            >
              <i className="fa-solid fa-right-from-bracket fa-1x Sidebar__nav-link__icon me-3"></i>
              Odhlásiť
            </NavLink>
          </div>
        </CSidebarNav>
      </CSidebar>
      <div id="side-backdrop" onClick={sidebarToggle} className={showBackground}></div>
    </>
  );
};

export default Sidebar;
