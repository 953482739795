import { useDispatch, useSelector } from 'react-redux';

import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from '@coreui/react';

import { authGetProfileActions } from '../../../saga/auth/actions';
import { selectLoggedUser } from '../../../saga/auth/selectors';
import { selectSelectedDevice } from '../../../saga/devices/selectors';
import { layoutSidebarShowActions } from '../../../saga/layout/actions';

const Navigation = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedUser);
  const sidebarToggle = () => {
    dispatch(layoutSidebarShowActions());
  };

  return (
    <CHeader position="sticky" className="Navigation print">
      <CContainer fluid>
        <CHeaderToggler className="Navigation__toggler show-1250" onClick={sidebarToggle}>
          <i className="fa-solid fa-bars"></i>
        </CHeaderToggler>
        <div className="right-align">
          <div className="user-name">{user?.name}</div>
          <div className="user-email">{user?.email}</div>
        </div>
      </CContainer>
    </CHeader>
  );
};

export default Navigation;
