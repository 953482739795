import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import { clearReduxStore } from './helpers/redux/redux-helpers';
import { RouteMapper } from './helpers/route/RouteMapper';
import { persistor, store } from './helpers/store/configure-store';
import { history } from './helpers/store/root-reducer';
import Loading from './views/components/Loading/Loading';
import ToastMessages from './views/components/ToastWrapper/ToastWrapper';
import { AppLayout } from './views/layout/AppLayout/AppLayout';
import Modal from './views/modal/Modal/Modal';
import { ModalProvider } from './views/modal/ModalProvider';
import 'react-toastify/dist/ReactToastify.css';

import './helpers/language';

const AppBase = () => {
  const [i18nReady, setI18nReady] = useState(i18n.isInitialized);

  const dispatch = useDispatch();

  useEffect(() => {
    i18n.on('initialized', () => setI18nReady(true));
  }, []);

  useEffect(() => {
    const currentVersion = process.env.REACT_APP_VERSION;
    const storedVersion = localStorage.getItem('mentonAppVersion');

    if (currentVersion !== storedVersion) {
      dispatch(clearReduxStore());
      localStorage.setItem('mentonAppVersion', currentVersion || '');
    }
  }, [dispatch]);

  if (!i18nReady) {
    return <Loading />;
  }

  return (
    <AppLayout>
      <RouteMapper />
    </AppLayout>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HistoryRouter history={history}>
          <ModalProvider>
            <Modal />
            <ToastMessages />
            <AppBase />
          </ModalProvider>
        </HistoryRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
