import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { AuthRoutes } from '../../helpers/route/routes/auth-routes';
import { history } from '../../helpers/store/root-reducer';
import { User, UserProfile } from '../../models/User';
import {
  AuthActionTypes,
  authGetProfileActions,
  authLoginActions,
  authLogoutActions,
  ForgottenPasswordActions,
  LoginActions,
  ResetPasswordActions,
} from './actions';
import { api } from './api';

/* STATE */
export interface AuthState {
  user: User | null; // Pri logine a podobne aby sme zistili ci je authentifikovany
  profile: UserProfile | null; // Na ziskavanie udajov pre tasky a podobne
  loading: LoadingStatus;
}

/* REDUCERS */
const initialState: AuthState = {
  user: null,
  profile: null,
  loading: LoadingStatus.initial,
};

const user = createReducer(initialState.user, {
  [AuthActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.user,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.user,
  },
});

const profile = createReducer(initialState.profile, {
  [AuthActionTypes.GetProfile]: {
    [RequestActionTypes.SUCCESS]: (state: UserProfile | null, payload: UserProfile) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.user,
  },
});

const loading = createLoadingStateReducer(initialState.loading, {
  [AuthActionTypes.Login]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

export default combineReducers<AuthState>({
  user,
  profile,
  loading,
});

/* SAGAS */
function* login({ payload }: AppAction<LoginActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.login, payload.email, payload.password);

  if (resp.ok) {
    yield put(authLoginActions.success(resp.data.user));
    history.push(buildRoute(AppRoutes.Orders));
  } else {
    yield put(authLoginActions.failure());
  }
}

function* logout() {
  const resp: ExtendedAxiosResponse = yield call(api.logout);

  if (resp.ok) {
    yield put(authLogoutActions.success());
  } else {
    yield put(authLogoutActions.failure());
  }
}

function* resetPassword({ payload }: AppAction<ResetPasswordActions>) {
  const resp: ExtendedAxiosResponse = yield call(
    api.resetPassword,
    payload.password,
    payload.confirmPassword
  );
  //   if (resp.ok) {
  //     yield put(authLogoutActions.success());
  //     history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
  //   } else {
  //     yield put(authLogoutActions.failure());
  //   }
}

function* forgottenPassword({ payload }: AppAction<ForgottenPasswordActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.forgottenPassword, payload.email);

  // if (resp.ok) {
  //     yield put(authLogoutActions.success());
  //     history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
  //   } else {
  //     yield put(authLogoutActions.failure());
  //   }
}

function* getProfile() {
  const resp: ExtendedAxiosResponse = yield call(api.getProfile);

  if (resp.ok) {
    yield put(authGetProfileActions.success({ ...resp.data }));
  } else {
    yield put(authGetProfileActions.failure());
  }
}

/* EXPORT */
export function* authSaga() {
  yield takeLatest(createActionType(AuthActionTypes.Login, RequestActionTypes.REQUEST), login);
  yield takeLatest(
    createActionType(AuthActionTypes.ResetPassword, RequestActionTypes.REQUEST),
    resetPassword
  );
  yield takeLatest(createActionType(AuthActionTypes.Logout, RequestActionTypes.REQUEST), logout);
  yield takeLatest(
    createActionType(AuthActionTypes.GetProfile, RequestActionTypes.REQUEST),
    getProfile
  );
  yield takeLatest(
    createActionType(AuthActionTypes.ForgottenPassword, RequestActionTypes.REQUEST),
    forgottenPassword
  );
}
