import { CustomerType, YearlyPrice } from './Customer';
import { Departure } from './Departure';
import { Device } from './Device';
import { Document } from './Document';
import { User } from './User';

export enum OrderTypeService {
  MAILED = 'Mailová',
  TELEPHONE = 'Telefonická',
  FORM = 'Formulár',
  COMPAINT = 'Reklamácia',
  PERSONAL = 'Osobná',
  FINISHING = 'Dokončovanie',
}

export enum OrderTypeMontage {
  WITHOUT_DATE = 'Objednávka bez termínu',
  WITH_DATE = 'Objednávka s termínom ( penále, pokuta )',
  CONTRACT_FOR_WORK = 'Zmluva o dielo ( penále )',
}

export const OrderType = {
  ...OrderTypeService,
  ...OrderTypeMontage,
};

export type OrderType = typeof OrderType;

export enum OrderStatusService {
  WAITING_FOR_PROCESSING = 'Čaká na spracovanie',
  WAITING_FOR_DEMAND = 'Čaká na dopyt',
  WAITING_FOR_CP = 'Čaká na CP',
  WAITING_FOR_ORDERING = 'Čaká na objednanie',
  WAITING_FOR_PAYMENT_ZP = 'Čaká na úhradu ZP',
  WAITING_FOR_DATE_WORK = 'Čaká na termín výkonu',
  WAITING_FOR_COMPAINT = 'Čaká na reklamáciu',
  WAITING_FOR_SPARE_PART = 'Čaká na náhradný diel',
  WAITING_FOR_WORK = 'Čaká na vykonanie',
  WAITING_FOR_REVISION = 'Čaká na revíznu správu',
  WAITING_FOR_INVOICE = 'Čaká na fakturáciu',
  DONE = 'Dokončené',
  STORNO = 'Storno',
}

export enum OrderStatusMontage {
  WAITING_FOR_DELIVERY = 'Čaká na dodanie',
  SUPPLEMENTING_ORDER = 'Doplnenie objednávky',
  PARTIALLY_STOCKED = 'Čiastočne naskladnené',
  WAITING_FOR_ASSEMBLY_DATE = 'Čaká na termín montáže',
  WAITING_FOR_WORK = 'Čaká na vykonanie',
  WAITING_FOR_COMPLETION = 'Čaká na dokončenie',
  COMPAINT = 'Reklamácia',
  WAITING_FOR_REVISION = 'Čaká na revíznu správu',
  WAITING_FOR_INVOICE = 'Čaká na fakturáciu',
  DONE = 'Dokončené',
}

export const OrderStatus = {
  ...OrderStatusService,
  ...OrderStatusMontage,
};

export type OrderStatus = typeof OrderStatus;

export interface AdditionInfo {
  dateOfAcceptance: Date;
}

export interface Order {
  id: string;
  intId: string;
  customerId?: string;
  requestId: string;
  orderId?: string;
  companyName: string;
  type: keyof OrderType;
  state: keyof OrderStatus;
  customer: CustomerOrder;
  additionInfo: AdditionInfo;
  places: OrderPlace[];
  departures: Departure[];
  devices: Device[];
  note: string;
  date: string;
  documents: Document[];
  department: OrderDepartment;
  seller: User | null;
  sellerId: string | null;
  users: User[];
  purchasePrice: number | null;
  sellingPrice: number | null;
}

export interface OrderValue {
  customerId: string;
  type: string;
  state: string;
  date: Date;
  note: string;
  places?: OrderPlace[];
  devices?: Device[];
}

export interface CreateOrder {
  customerId: string;
  type: string;
  state: string;
  date: Date;
  note: string;
  department: OrderDepartment;
  places?: string[];
  devices?: string[];
  users?: string[];
  sellerId?: string | null;
  purchasePrice?: number;
  sellingPrice?: number;
}

export interface UpdateOrder extends CreateOrder {
  id: string;
  intId: string;
}

export interface OrderPlace {
  id: string;
  orderId: string;
  residence: string;
  type: CustomerType;
  emergency: boolean;
  yearlyPrice: YearlyPrice;
  contact?: string;
  email?: string;
  phone?: string;
}

export enum DateFilterType {
  ALL = 'Všetky',
  RECEIVED_ONE_DAY = 'Prijaté v deň',
  RECEIVED_BETWEEN_DAYS = 'Prijaté v rozpätí dní',
  UPDATED_ONE_DAY = 'Aktualizované v deň',
  UPDATED_BETWEEN_DAYS = 'Aktualizované v rozpätí dní',
}

export interface OrderResponse {
  total: number;
  limit: number;
  skip: number;
  data: Order[];
}

export interface CustomerOrder {
  id: string;
  type?: string;
  companyName: string;
  contact?: string;
  email?: string;
  phone?: string;
  residence?: string;
  ico?: string;
  dic?: string;
  icdph?: string;
}

export interface OrderFilter {
  departments: OrderDepartment[];
  id?: string;
  companyName?: string;
  sellerName?: string;
  state?: string;
  dateMode?: keyof typeof DateFilterType;
  dateFrom?: string;
  dateTo?: string;
  updatedDateFrom?: string;
  updatedDateTo?: string;
  sort?: number;
  notState?: string[];
  document?: string;
  oneDay?: string;
  twoDayFrom?: string;
  twoDayTo?: string;
  intId?: string;
  residence?: string;
  user?: string;
}

export enum OrderDepartment {
  bs = 'bs',
  bm = 'bm',
  zs = 'zs',
  zm = 'zm',
}

export interface OrderDepartmentOption {
  label: string;
  value: OrderDepartment;
}

export const allOrderDepartmentsOptions: OrderDepartmentOption[] = [
  {
    label: 'Bratislava - Servis',
    value: OrderDepartment.bs,
  },
  {
    label: 'Bratislava - Montáž',
    value: OrderDepartment.bm,
  },
  {
    label: 'Žilina - Servis',
    value: OrderDepartment.zs,
  },
  {
    label: 'Žilina - Montáž',
    value: OrderDepartment.zm,
  },
];

export interface OrderNotification {
  emails: string[];
  note: string;
  orderNumber: string;
  customerCompanyName: string;
  seller: string;
  url: string;
}
