import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import {
  CreateOrder,
  Order,
  OrderFilter,
  OrderNotification,
  OrderPlace,
  UpdateOrder,
} from '../../models/Order';

/* ACTION TYPES */
export enum OrdersActionTypes {
  CreateOrder = '@@Orders/ADD_ORDER',
  GetOrders = '@@Orders/GET_ORDERS',
  GetOrder = '@@Orders/GET_ORDER',
  DeleteOrder = '@@Orders/DELETE_ORDER',
  UpdateOrder = '@@Orders/UPDATE_ORDER',
  SelectOrder = '@@Orders/SELECT_ORDER',
  CleanOrder = '@@Orders/CLEAN_ORDER',
  SetFilter = '@@Orders/SET_FILTER',
  CleanFilter = '@@Orders/CLEAN_FILTER',
  FilterOrders = '@@Orders/FILTER_ORDERS',
  CreateConcept = '@@Orders/CREATE_CONCEPT',
  CleanOrders = '@@Orders/CLEAN_ORDERS',
  CreatePlace = '@@Orders/CREATE_PLACE',
  CreateUser = '@@Orders/CREATE_USER',
  DeleteUser = '@@Orders/DELETE_USER',
  DeletePlace = '@@Orders/DELETE_PLACE',
  Notify = '@@Orders/NOTIFY',
}

/* ACTION MODELS */
export interface CreateOrderActions {
  order: Order;
}

export interface GetOrderActions {
  id: string;
  orders: Order[];
}

export interface DeleteOrderActions {
  id: string;
  customer?: string;
}

export interface UpdateOrderActions {
  updatedOrder: Order;
}

export interface CreateConceptActions {
  concept: Order;
}

export interface SelectOrderActions {
  selectedOrder: Order;
}

export interface GetOrdersActions {
  limit: number;
  skip: number;
  filter?: OrderFilter;
}

export interface OrderUserActions {
  orderId: string;
  userId: string;
}

interface CreateOrderPlaceActions extends Omit<OrderPlace, 'yearlyPrice' | 'type' | 'emergency'> {}
interface DeleteOrderPlaceActions extends Omit<CreateOrderPlaceActions, 'residence'> {}

/* ACTIONS */
export const ordersSelectOrderActions = createActionCreator<SelectOrderActions>(
  OrdersActionTypes.SelectOrder
);
export const ordersCreateOrderActions = createApiActionCreators<CreateOrder>(
  OrdersActionTypes.CreateOrder
);
export const ordersDeleteOrderActions = createApiActionCreators<DeleteOrderActions>(
  OrdersActionTypes.DeleteOrder
);
export const ordersUpdateOrderActions = createApiActionCreators<UpdateOrder>(
  OrdersActionTypes.UpdateOrder
);
export const ordersCreatePlaceActions = createApiActionCreators<CreateOrderPlaceActions>(
  OrdersActionTypes.CreatePlace
);
export const ordersDeletePlaceActions = createApiActionCreators<DeleteOrderPlaceActions>(
  OrdersActionTypes.DeletePlace
);
export const ordersGetOrdersActions = createApiActionCreators(OrdersActionTypes.GetOrders);
export const ordersGetOrderActions = createApiActionCreators<string>(OrdersActionTypes.GetOrder);
export const ordersCleanOrderActions = createActionCreator(OrdersActionTypes.CleanOrder);
export const ordersCleanOrdersActions = createActionCreator(OrdersActionTypes.CleanOrders);
export const ordersCleanFilterActions = createActionCreator(OrdersActionTypes.CleanFilter);
export const ordersCreateConceptActions = createApiActionCreators<CreateConceptActions>(
  OrdersActionTypes.CreateConcept
);
export const ordersCreateUserActions = createApiActionCreators<OrderUserActions>(
  OrdersActionTypes.CreateUser
);
export const ordersDeleteUserActions = createApiActionCreators<OrderUserActions>(
  OrdersActionTypes.DeleteUser
);
export const ordersSetFilterActions = createActionCreator(OrdersActionTypes.SetFilter);
export const orderNotificationActions = createApiActionCreators<OrderNotification>(
  OrdersActionTypes.Notify
);
