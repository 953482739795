import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { environment } from '../environments/environment';
import { Language } from '../models/Language';
import sk from '../resources/translations/sk.json';

import 'dayjs/locale/sk';
import 'dayjs/locale/cs';
import 'dayjs/locale/de-at';
import 'dayjs/locale/pl';
import 'dayjs/locale/en-gb';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);

i18n.use(initReactI18next).init({
  resources: {
    [Language.Slovak]: { translation: sk },
  },
  debug: environment.debug,
  returnEmptyString: true,
  initImmediate: false,
  supportedLngs: environment.i18n.languages,
  fallbackLng: environment.i18n.defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'],
  },
});

i18n.on('languageChanged', (language: string) => {
  if (language === Language.Slovak) {
    dayjs.locale('sk');
  } else if (language === Language.Czech) {
    dayjs.locale('cs');
  } else if (language === Language.Polish) {
    dayjs.locale('pl');
  } else if (language === Language.German) {
    dayjs.locale('de-at');
  } else if (language === Language.English) {
    dayjs.locale('en-gb');
  }
});

export { i18n };
