import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsLogged } from '../../../saga/auth/selectors';
import Navigation from '../../components/Navigation/Navigation';
import Sidebar from '../../components/Sidebar/Sidebar';

interface Props {
  children: React.ReactNode;
}

export const AppLayout = ({ children }: Props) => {
  const isLogged = useSelector(selectIsLogged);

  if (!isLogged) {
    return <div>{children}</div>;
  }

  return (
    <div>
      <Sidebar />
      <div className="wrapper d-flex flex-column bg-light">
        <Navigation />
        <div className="AppLayout__children">{children}</div>
      </div>
    </div>
  );
};
