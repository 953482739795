import React from 'react';

import { AppRoute } from '../Route';

export enum AuthRoutes {
  Login = 'login',
  Reset = 'reset',
}

const authRoutes: AppRoute[] = [
  {
    path: AuthRoutes.Login,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Auth/Login/Login')),
  },
  {
    path: AuthRoutes.Reset,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Auth/ResetPassword/ResetPassword')),
  },
];

export default authRoutes;
