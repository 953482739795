import React from 'react';

import { AppRoute } from '../Route';

export enum StatRoutes {
  OrderState = 'orderState',
  OrderType = 'orderType',
  OrderSeller = 'orderSeller',
  DepartmentSales = 'orderDepartmentSales',
  OrderTypeSales = 'orderTypeSales',
  DeviceTypeSales = 'deviceTypeSales',
}

const statRoutes: AppRoute[] = [
  {
    path: StatRoutes.OrderState,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Stats/OrderState/OrderState')),
  },
  {
    path: StatRoutes.OrderType,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Stats/OrderType/OrderType')),
  },
  {
    path: StatRoutes.OrderSeller,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Stats/OrderSeller/OrderSeller')),
  },
  {
    path: StatRoutes.DepartmentSales,
    exact: true,
    authenticated: true,
    component: React.lazy(
      () => import('../../../views/pages/Stats/OrderDepartmentSales/OrderDepartmentSales')
    ),
  },
  {
    path: StatRoutes.OrderTypeSales,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Stats/OrderTypeSales/OrderTypeSales')),
  },
  {
    path: StatRoutes.DeviceTypeSales,
    exact: true,
    authenticated: true,
    component: React.lazy(
      () => import('../../../views/pages/Stats/DeviceTypeSales/DeviceTypeSales')
    ),
  },
];

export default statRoutes;
