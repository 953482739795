import React from 'react';

import { AppRoute } from '../Route';
import authRoutes from './auth-routes';
import customerRoutes from './customer-routes';
import departureRoutes from './departure-routes';
import deviceRoutes from './device-routes';
import orderRoutes from './order-routes';
import statRoutes from './stat-routes';
import userRoutes from './user-routes';

export enum AppRoutes {
  Auth = '/auth',
  Orders = '/orders',
  Customers = '/customers',
  Devices = '/devices',
  Users = '/users',
  Departures = '/departures',
  Stats = '/stats',
}

const routes: AppRoute[] = [
  {
    path: '*',
  },
  {
    path: AppRoutes.Auth,
    children: authRoutes,
  },
  {
    path: AppRoutes.Orders,
    authenticated: true,
    children: orderRoutes,
  },
  {
    path: AppRoutes.Customers,
    authenticated: true,
    children: customerRoutes,
  },
  {
    path: AppRoutes.Departures,
    authenticated: true,
    children: departureRoutes,
  },
  {
    path: AppRoutes.Users,
    authenticated: true,
    children: userRoutes,
  },
  {
    path: AppRoutes.Devices,
    authenticated: true,
    children: deviceRoutes,
  },
  {
    path: AppRoutes.Stats,
    authenticated: true,
    children: statRoutes,
  },
];

export default routes;
