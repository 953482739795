import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { Document, DocumentFilter, DocumentValue, SendDocumentEmail } from '../../models/Document';

/* ACTION TYPES */
export enum DocumentsActionTypes {
  CreateDocument = '@@Documents/CREATE_DOCUMENT',
  GetDocuments = '@@Documents/GET_DOCUMENTS',
  GetDocument = '@@Documents/GET_DOCUMENT',
  GetLastDocument = '@@Documents/GET_LAST_DOCUMENT',
  ClearGetLastDocument = '@@Documents/CLEAR_GET_LAST_DOCUMENT',
  UpdateDocument = '@@Documents/UPDATE_DOCUMENT',
  DeleteDocument = '@@Documents/DELETE_DOCUMENT',
  SelectDocument = '@@Documents/SELECT_DOCUMENT',
  CleanDocument = '@@Documents/CLEAN_SELECTED_DOCUMENT',
  SetFilter = '@@Documents/SET_FILTER',
  SendEmail = '@@Documents/SEND_EMAIL',
}

/* ACTION MODELS */
export interface GetDocumentsActions {
  limit: number;
  skip: number;
  filter?: DocumentFilter;
}

export interface GetDocumentActions {
  id: string;
  name: string;
  download: boolean;
}

export interface GetLastDocumentActions {
  internalNumberPrefix: string;
}

/* ACTIONS */
export const documentsCreateDocumentActions = createApiActionCreators<DocumentValue>(
  DocumentsActionTypes.CreateDocument
);
export const documentsGetDocumentsActions = createApiActionCreators(
  DocumentsActionTypes.GetDocuments
);
export const documentsGetDocumentActions = createApiActionCreators<GetDocumentActions>(
  DocumentsActionTypes.GetDocument
);
export const documentsGetLastDocumentActions = createApiActionCreators<GetLastDocumentActions>(
  DocumentsActionTypes.GetLastDocument
);
export const documentsClearGetLastDocumentActions = createActionCreator(
  DocumentsActionTypes.ClearGetLastDocument
);
export const documentsUpdateDocumentActions = createApiActionCreators<Document>(
  DocumentsActionTypes.UpdateDocument
);
export const documentsDeleteDocumentActions = createApiActionCreators<string>(
  DocumentsActionTypes.DeleteDocument
);
export const documentsSelectDocumentActions = createActionCreator<string>(
  DocumentsActionTypes.SelectDocument
);
export const documentsCleanDocumentActions = createActionCreator(
  DocumentsActionTypes.CleanDocument
);
export const documentsSetFilterActions = createActionCreator(DocumentsActionTypes.SetFilter);
export const documentsSendEmailActions = createApiActionCreators<SendDocumentEmail>(
  DocumentsActionTypes.SendEmail
);
