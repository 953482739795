import {
  AnyAction,
  applyMiddleware,
  CombinedState,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { environment } from '../../environments/environment';
import { AuthState } from '../../saga/auth/ducks';
import { CustomersState } from '../../saga/customers/ducks';
import { DeparturesState } from '../../saga/departures/ducks';
import { DevicesState } from '../../saga/devices/ducks';
import { DocumentsState } from '../../saga/documents/ducks';
import { LayoutState } from '../../saga/layout/ducks';
import { OrdersState } from '../../saga/orders/ducks';
import { StatsState } from '../../saga/stats/ducks';
import { ToastState } from '../../saga/toast/ducks';
import { UsersState } from '../../saga/users/ducks';
import { CLEAR_REDUX_STORE } from '../redux/redux-helpers';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const appReducer = (
  state:
    | CombinedState<{
        auth: CombinedState<AuthState>;
        toast: CombinedState<ToastState>;
        orders: CombinedState<OrdersState>;
        customers: CombinedState<CustomersState>;
        devices: CombinedState<DevicesState>;
        layout: CombinedState<LayoutState>;
        departures: CombinedState<DeparturesState>;
        users: CombinedState<UsersState>;
        documents: CombinedState<DocumentsState>;
        stats: CombinedState<StatsState>;
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === CLEAR_REDUX_STORE) {
    state = undefined;
    localStorage.removeItem('persist:root');
  }

  return rootReducer(state, action);
};

const persistedReducer = persistReducer(
  {
    key: 'menton-fe',
    storage,
    blacklist: ['documents', 'layout', 'toast'],
    debug: environment.debug,
  },
  appReducer
);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
