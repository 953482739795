import React, { createContext, useContext, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

interface ContextValue {
  modals: React.ReactNode[];
  showModal: (content: React.ReactNode) => void;
  closeModal: () => void;
  closeAllModals: () => void;
}

const ModalContext = createContext<ContextValue>({
  modals: [],
  showModal: () => {},
  closeModal: () => {},
  closeAllModals: () => {},
});

export const ModalProvider = ({ children }: Props) => {
  const [modals, setModals] = useState<React.ReactNode[]>([]);

  const showModal = (content: React.ReactNode) => {
    setModals((val) => [...val, content]);
  };

  const closeModal = () => {
    setModals((val) => val.filter((item, i) => i < val.length - 1));
  };

  const closeAllModals = () => {
    setModals([]);
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        showModal,
        closeModal,
        closeAllModals,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ContextValue => {
  return useContext(ModalContext);
};
