import { AppState } from '../../helpers/store/AppState';
import { createOptions } from '../../helpers/utils';
import { TableCustomer } from '../../models/Customer';

export const selectCustomers = (state: AppState) => state.customers;

export const selectCustomersList = (state: AppState) =>
  selectCustomers(state).customerResponse.data;

export const selectCustomersLoading = (state: AppState) => selectCustomers(state).loading;

export const selectSelectedCustomer = (state: AppState) => selectCustomers(state).selectedCustomer;

export const selectCustomersOptions = (state: AppState) =>
  selectCustomers(state).customerResponse.data.map((customer: TableCustomer) =>
    createOptions(customer.companyName, customer.id)
  );

export const selectPlacesOptions = (state: AppState) =>
  selectCustomers(state).customerResponse.data.map((customer: TableCustomer) =>
    createOptions(customer.residence, customer.residence)
  );

export const selectCustomersTotal = (state: AppState) => state.customers.customerResponse.total;

export const selectCustomersFilter = (state: AppState) => state.customers.customerFilter;
