import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { CreateUser, UpdateUser, User, UserFilter } from '../../models/User';

/* ACTION TYPES */
export enum UsersActionTypes {
  CreateUser = '@@Users/CREATE_USER',
  GetUsers = '@@Users/GET_USERS',
  GetUser = '@@Users/GET_USER',
  DeleteUser = '@@Users/DELETE_USER',
  UpdateUser = '@@Users/UPDATE_USER',
  SelectUser = '@@Users/SELECT_USER',
  CleanUser = '@@Users/CLEAN_SELECTED_USER',
  SetFilter = '@@Users/SET_USER_FILTER',
  SetCurrentPage = '@@Users/SET_USER_CURRENT_PAGE',
  CleanUsers = '@@Users/CLEAN_USERS',
}

/* ACTION MODELS */
export interface GetUsersAction {
  limit: number;
  skip: number;
  filter?: UserFilter | null;
}

export interface CreateUserActions {
  newUser: any; //UserValues
}

export interface GetUserActions {
  id: string;
  users: User[];
}

export interface DeleteUserActions {
  id: string;
}

export interface SelectUserActions {
  selectedUser: User;
}

/* ACTIONS */
export const usersCreateUserActions = createApiActionCreators<CreateUser>(
  UsersActionTypes.CreateUser
);
export const usersDeleteUserActions = createApiActionCreators<string>(UsersActionTypes.DeleteUser);
export const usersUpdateUserActions = createApiActionCreators<UpdateUser>(
  UsersActionTypes.UpdateUser
);
export const usersGetUsersActions = createApiActionCreators<GetUsersAction>(
  UsersActionTypes.GetUsers
);
export const usersGetUserActions = createApiActionCreators<string>(UsersActionTypes.GetUser);
export const usersSelectUserActions = createActionCreator<SelectUserActions>(
  UsersActionTypes.SelectUser
);
export const usersCleanUserActions = createActionCreator(UsersActionTypes.CleanUser);
export const usersSetFilterActions = createActionCreator(UsersActionTypes.SetFilter);
export const usersCleanUsersActions = createActionCreator(UsersActionTypes.CleanUsers);
export const usersSetCurrentPageActions = createActionCreator(UsersActionTypes.SetCurrentPage);
