import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { environment } from '../environments/environment';
import { authLogoutActions } from '../saga/auth/actions';
import { toastCreateWarnAction } from '../saga/toast/actions';
import { store } from './store/configure-store';

export interface ExtendedAxiosResponse<T = any> {
  ok: boolean;
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
  error?: any;
}

const { protocol, host, port, basicAuthToken } = environment.api;

export const apiClient = axios.create({
  responseType: 'json',
  baseURL: `${protocol}://${host}${port ? `:${port}` : ''}`,
  withCredentials: true,
  paramsSerializer: (params: any) => {
    return qs.stringify(params);
  },
});

apiClient.interceptors.request.use((config) => {
  config.headers = {
    ...(config.headers || {}),
    'Cache-Control': 'no-cache',
    'Access-Control-Max-Age': 600,
  };

  if (basicAuthToken) {
    config.headers['Authorization'] = `Basic ${basicAuthToken}`;
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => normalizeSuccessResponse(response),
  (error) => normalizeErrorResponse(error)
);

const normalizeSuccessResponse = (response: AxiosResponse): ExtendedAxiosResponse => {
  return {
    ...response,
    ok: true,
  };
};

const formatAxiosError = (error: any): ExtendedAxiosResponse => {
  return {
    ok: false,
    data: error.response?.data,
    status: error.response?.status,
    statusText: error.response?.statusText,
    headers: error.response?.headers,
    config: error.config,
    request: error.request,
    error,
  };
};

const normalizeErrorResponse = (error: any): ExtendedAxiosResponse => {
  const { data, status, config } = error.response;

  if (status === 401) {
    if (config.method?.toUpperCase() === 'DELETE' && config.url === '/auth') {
      store.dispatch(authLogoutActions.success());
    } else {
      store.dispatch(authLogoutActions.request());
    }

    //store.dispatch(toastCreateWarnAction('Toast.Warning.Unauthorized'));
  } else if (status === 500) {
    // Show general error
  }

  if (environment.debug) {
    console.error(
      `Request failed with status code %c${status}\n`,
      'font-weight: bold;',
      `${config.method?.toUpperCase() || '-'} ${config.url}\n\n`,
      'Request:\n',
      config.data,
      '\nResponse:\n',
      data
    );
  }

  if (!error.response || error.isAxiosError) {
    // Show general error
    return formatAxiosError(error);
  }

  return formatAxiosError(error);
};

export const documentURL = `${environment.api.protocol}://${environment.api.host}${
  environment.api.port ? `:${environment.api.port}` : ''
}/document/`;
