import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Loading from '../../views/components/Loading/Loading';
import ExtendedRoute from './ExtendedRoute';
import { AppRoute } from './Route';
import appRoutes from './routes/app-routes';

export const RouteMapper = () => {
  const renderRoutes = (routes: AppRoute[]) => {
    return routes.map((route) => (
      <Route key={route.path} path={route.path} element={<ExtendedRoute route={route} />}>
        {route.children && renderRoutes(route.children)}
      </Route>
    ));
  };

  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>{renderRoutes(appRoutes)}</Routes>
    </React.Suspense>
  );
};
