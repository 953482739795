import { createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum AuthActionTypes {
  Login = '@@Auth/LOGIN',
  Logout = '@@Auth/LOGOUT',
  ResetPassword = '@@Auth/RESET_PASSWORD',
  GetProfile = '@@Auth/GET_PROFILE',
  ForgottenPassword = '@@Auth/FORGOTTEN_PASSWORD',
}

/* ACTION MODELS */
export interface LoginActions {
  email: string;
  password: string;
}

export interface ResetPasswordActions {
  password: string;
  confirmPassword: string;
}

export interface ForgottenPasswordActions {
  email: string;
}

/* ACTIONS */
export const authLoginActions = createApiActionCreators<LoginActions>(AuthActionTypes.Login);
export const authResetPasswordActions = createApiActionCreators<ResetPasswordActions>(
  AuthActionTypes.ResetPassword
);
export const authLogoutActions = createApiActionCreators(AuthActionTypes.Logout);
export const authGetProfileActions = createApiActionCreators(AuthActionTypes.GetProfile);
export const authForgottenPasswordActions = createApiActionCreators<ForgottenPasswordActions>(
  AuthActionTypes.ForgottenPassword
);
