import { combineReducers } from 'redux';

import { createReducer } from '../../helpers/redux/redux-helpers';

import { LayoutActionTypes } from './actions';

/* STATE */
export interface LayoutState {
  sidebarShow: boolean;
}

/* REDUCERS */
const initialState: LayoutState = {
  sidebarShow: false,
};

const sidebarShow = createReducer(initialState.sidebarShow, {
  [LayoutActionTypes.SidebarShow]: (state: boolean) => !state,
});

export default combineReducers<LayoutState>({
  sidebarShow,
});
