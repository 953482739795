import { AppState } from '../../helpers/store/AppState';

const selectDepartures = (state: AppState) => state.departures;

export const selectDeparturesList = (state: AppState) =>
  selectDepartures(state).departureResponse.data;

export const selectDeparturesLoading = (state: AppState) => selectDepartures(state).loading;

export const selectSelectedDeparture = (state: AppState) =>
  selectDepartures(state).selectedDeparture;

export const selectDeparturesTotal = (state: AppState) => state.departures.departureResponse.total;

export const selectDepartureFilter = (state: AppState) => selectDepartures(state).departureFilter;

export const selectDeparturesFilter = (state: AppState) => state.departures.departureFilter;

//export const selectDeparturesCurrentPage = (state: AppState) => selectDepartures(state).currentPage;

export const selectDeparturesViewMode = (state: AppState) => selectDepartures(state).viewMode;
