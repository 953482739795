import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectLoggedUser } from '../../saga/auth/selectors';
import { hasOrderPermission } from '../utils';
import { AppRoute } from './Route';
import { buildRoute } from './route-builder';
import { AppRoutes } from './routes/app-routes';
import { AuthRoutes } from './routes/auth-routes';
import { StatRoutes } from './routes/stat-routes';

interface Props {
  route: AppRoute;
}

const ExtendedRoute = ({ route }: Props) => {
  const user = useSelector(selectLoggedUser);

  if (route.path != '/auth' && route.path != 'reset' && route.path != 'login' && !user) {
    return <Navigate to={buildRoute([AppRoutes.Auth, AuthRoutes.Login])} replace />;
  }

  if (!route.authenticated && user) {
    if (user.role.toString() === 'ADMIN' || user.permissions?.includes('stats')) {
      return <Navigate to={buildRoute(`${AppRoutes.Stats}/${StatRoutes.OrderState}`)} replace />;
    } else if (hasOrderPermission(user, 'read')) {
      return <Navigate to={buildRoute(AppRoutes.Orders)} replace />;
    } else if (user.permissions?.includes('customer.create')) {
      return <Navigate to={buildRoute(AppRoutes.Customers)} replace />;
    } else if (user.permissions?.includes('departure.read')) {
      return <Navigate to={buildRoute(AppRoutes.Departures)} replace />;
    } else if (user.permissions?.includes('device.read')) {
      return <Navigate to={buildRoute(AppRoutes.Devices)} replace />;
    } else {
      return <Navigate to={buildRoute(AppRoutes.Users)} replace />;
    }
  }

  //if (route.permission && !user?.permissions?.includes(route.permission)) {
  //  return <Navigate to={buildRoute(AppRoutes.Orders)} replace />;
  //}

  if (route.component) {
    return <route.component />;
  }

  if (route.children) {
    return <Outlet />;
  }

  return <></>;
};

export default ExtendedRoute;
