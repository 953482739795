import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { CustomerFilter, Customer, CustomerValue, CustomerPlace } from '../../models/Customer';
import { Device } from '../../models/Device';

/* ACTION TYPES */
export enum CustomersActionTypes {
  Create = '@@Customers/CREATE',
  CreateCustomer = '@@Customers/CREATE_CUSTOMER',
  GetCustomers = '@@Customers/GET_CUSTOMERS',
  GetCustomer = '@@Customers/GET_CUSTOMER',
  DeleteCustomer = '@@Customers/DELETE_CUSTOMER',
  UpdateCustomer = '@@Customers/UPDATE_CUSTOMER',
  UpdateCreationCustomer = '@@Customers/UPDATE_CREATION_CUSTOMER',
  SelectCustomer = '@@Customers/SELECT_CUSTOMER',
  CleanCustomer = '@@Customers/CLEAN_SELECTED_CUSTOMER',
  SetFilter = '@@Customers/SET_FILTER',
  CreatePlace = '@@Customers/CREATE_PLACE',
  UpdatePlace = '@@Customers/UPDATE_PLACE',
  DeletePlace = '@@Customers/DELETE_PLACE',
}

/* ACTION MODELS */
export interface GetCustomersActions {
  limit: number;
  skip: number;
  filter?: CustomerFilter;
}

export interface CreateCustomerActions {
  customer: CustomerValue;
}

export interface UpdateCreationCustomerActions {
  places: string[];
  devices: Device[];
}

export interface GetCustomerActions {
  id: string;
  customers: Customer[];
}

export interface DeleteCustomerActions {
  id: string;
}

export interface SelectCustomerActions {
  customerName: string;
  customers: Customer[];
}

interface DeleteCustomerPlaceActions
  extends Omit<CustomerPlace, 'residence' | 'yearlyPrice' | 'type' | 'emergency'> {}

/* ACTIONS */
export const customersUpdateCreationCustomerActions =
  createActionCreator<UpdateCreationCustomerActions>(CustomersActionTypes.UpdateCreationCustomer);

export const customersCreateCustomerActions = createApiActionCreators<CustomerValue>(
  CustomersActionTypes.CreateCustomer
);
export const customersDeleteCustomerActions = createApiActionCreators<DeleteCustomerActions>(
  CustomersActionTypes.DeleteCustomer
);
export const customersUpdateCustomerActions = createApiActionCreators<Customer>(
  CustomersActionTypes.UpdateCustomer
);
export const customersCreatePlaceActions = createApiActionCreators<CustomerPlace>(
  CustomersActionTypes.CreatePlace
);
export const customersUpdatePlaceActions = createApiActionCreators<CustomerPlace>(
  CustomersActionTypes.UpdatePlace
);
export const customersDeletePlaceActions = createApiActionCreators<DeleteCustomerPlaceActions>(
  CustomersActionTypes.DeletePlace
);
export const customersGetCustomersActions = createApiActionCreators(
  CustomersActionTypes.GetCustomers
);
export const customersGetCustomerActions = createApiActionCreators<string>(
  CustomersActionTypes.GetCustomer
);
export const customersSelectCustomerActions = createActionCreator<SelectCustomerActions>(
  CustomersActionTypes.SelectCustomer
);
export const customersCleanCustomerActions = createActionCreator(
  CustomersActionTypes.CleanCustomer
);

export const customersSetFilterActions = createActionCreator(CustomersActionTypes.SetFilter);
