/* eslint-disable camelcase */
import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { CreateDeparture, DepartureFilter, UpdateDeparture } from '../../models/Departure';

export const api = {
  create: (departure: CreateDeparture): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/departure/create-complete', departure),
  get: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/departure/${id}`),
  getOrder: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/order/${id}`),
  getOrderPlaces: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/order/${id}/place`),
  getAll: (
    $limit: number,
    $skip: number,
    filter: DepartureFilter
  ): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/departure', {
      params: {
        $limit,
        $skip,
        '$sort[agreedDate]': filter?.sort ? filter?.sort : -1,
        'orderId': filter?.id,
        'company_name': filter?.companyName,
        'residence': filter?.residence,
        'date_from': filter?.dateFrom,
        'date_to': filter?.dateTo,
        'document': filter?.document,
        'user_id': filter?.userId,
        'department': filter?.departments,
        'intId': filter?.intId,
        'user': filter?.user,
      },
    }),
  getPlaces: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/departure/${id}/place`),
  update: (updatedDeparture: UpdateDeparture): Promise<ExtendedAxiosResponse> => {
    const { id, ...departure } = updatedDeparture;
    return apiClient.put(`/departure/${updatedDeparture.id}`, departure);
  },
  delete: (id: string): Promise<ExtendedAxiosResponse> => apiClient.delete(`/departure/${id}`),
  createPlace: (id: string, value: any): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/departure/${id}/place`, value),
  createDevice: (id: string, value: any): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/departure/${id}/device`, value),
  createUser: (id: string, value: any): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/departure/${id}/user`, value),
  deletePlace: (id: string, value: any): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/departure/${id}/place/${value}`),
  deleteDevice: (id: string, value: any): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/departure/${id}/device/${value}`),
  deleteUser: (id: string, value: any): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/departure/${id}/user/${value}`),
};
