import { toast } from 'react-toastify';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { OrderRoutes } from '../../helpers/route/routes/order-routes';
import { history } from '../../helpers/store/root-reducer';
import {
  CreateOrder,
  Order,
  OrderFilter,
  OrderNotification,
  OrderPlace,
  OrderResponse,
  UpdateOrder,
} from '../../models/Order';
import { customersGetCustomerActions } from '../customers/actions';
import { api as customerApi } from '../customers/api';
import {
  OrdersActionTypes,
  ordersDeleteOrderActions,
  ordersCreateOrderActions,
  ordersUpdateOrderActions,
  ordersGetOrdersActions,
  CreateConceptActions,
  ordersCreateConceptActions,
  ordersGetOrderActions,
  DeleteOrderActions,
  GetOrdersActions,
  ordersCreatePlaceActions,
  ordersDeletePlaceActions,
  OrderUserActions,
  orderNotificationActions,
} from './actions';
import { api } from './api';
import { selectOrderFilter } from './selectors';
const ITEMS_PER_PAGE = 50;

/* STATE */
export interface OrdersState {
  orderResponse: OrderResponse;
  concepts: Order[];
  selectedOrder: Order | null;
  loading: LoadingStatus;
  orderFilter: OrderFilter;
}

/* REDUCERS */
const initialState: OrdersState = {
  orderResponse: {
    limit: 0,
    total: 0,
    skip: 0,
    data: [],
  },
  orderFilter: { departments: [] },
  concepts: [],
  selectedOrder: null,
  loading: LoadingStatus.initial,
};

const orderResponse = createReducer(initialState.orderResponse, {
  [OrdersActionTypes.GetOrders]: {
    [RequestActionTypes.REQUEST]: initialState.orderResponse,
    [RequestActionTypes.SUCCESS]: (state: OrderResponse, payload: OrderResponse) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.orderResponse,
  },
});

const selectedOrder = createReducer(initialState.selectedOrder, {
  [OrdersActionTypes.GetOrder]: {
    [RequestActionTypes.SUCCESS]: (state: Order | null, payload: Order) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedOrder,
  },
  //[OrdersActionTypes.SelectOrder]: (state: Order | null, payload: SelectOrderActions) =>
  //  payload.selectedOrder,
  [OrdersActionTypes.CleanOrder]: () => initialState.selectedOrder,
});

const orderFilter = createReducer(initialState.orderFilter, {
  [OrdersActionTypes.SetFilter]: (state: OrderFilter, payload: OrderFilter) => payload,
  [OrdersActionTypes.CleanFilter]: () => initialState.orderFilter,
});

const concepts = createReducer(initialState.concepts, {
  [OrdersActionTypes.CreateConcept]: {
    [RequestActionTypes.SUCCESS]: (state: Order[], payload: Order) => payload,
  },
});

const loading = createLoadingStateReducer(initialState.loading, {
  [OrdersActionTypes.GetOrders]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [OrdersActionTypes.GetOrder]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [OrdersActionTypes.CreateOrder]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [OrdersActionTypes.UpdateOrder]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [OrdersActionTypes.DeleteOrder]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

export default combineReducers<OrdersState>({
  orderResponse,
  orderFilter,
  concepts,
  selectedOrder,
  loading,
});

/* SAGAS */
function* getOrders({ payload }: AppAction<GetOrdersActions>) {
  const selectedFilter: OrderFilter = yield select(selectOrderFilter);
  const departments = payload.filter?.departments;

  const resp: ExtendedAxiosResponse = yield call(api.getAll, payload.limit, payload.skip, {
    ...selectedFilter,
    ...(departments && departments.length && { departments: departments }),
  });
  if (resp.ok) {
    yield put(ordersGetOrdersActions.success(resp.data));
  } else {
    yield put(ordersGetOrdersActions.failure());
  }
}

function* getOrder({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.get, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.getPlaces, payload);
    if (res.ok) {
      const places = res.data.data
        .map((entry: { place: OrderPlace }) => entry.place)
        .filter((place: OrderPlace) => place);

      yield put(
        ordersGetOrderActions.success({
          ...resp.data,
          places: places,
        })
      );
    }
  } else {
    yield put(ordersGetOrderActions.failure());
  }
}

function* createOrder({ payload }: AppAction<CreateOrder>) {
  const resp: ExtendedAxiosResponse = yield call(api.create, payload);
  if (resp.ok) {
    yield put(ordersCreateOrderActions.success(resp.data));
    history.push(
      buildRoute([AppRoutes.Orders, OrderRoutes.DetailOrder], {
        orderId: resp.data.id,
      })
    );
  } else {
    yield put(ordersCreateOrderActions.failure());
  }
}

function* deleteOrder({ payload }: AppAction<DeleteOrderActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.delete, payload.id);
  if (resp.ok) {
    //yield put(ordersDeleteOrderActions.success(payload.id));
    if (payload.customer) {
      const res: ExtendedAxiosResponse = yield call(customerApi.get, payload.customer);
      if (res.ok) {
        const re: ExtendedAxiosResponse = yield call(customerApi.getPlaces, payload.customer);
        if (re.ok) {
          yield put(
            customersGetCustomerActions.success({
              ...res.data,
              places: re.data.data,
            })
          );
        }
      }
    } else {
      const selectFilter: OrderFilter = yield select(selectOrderFilter);

      const res: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, 0, selectFilter);
      if (res.ok) {
        yield put(ordersGetOrdersActions.success(res.data));
      }
    }
  } else {
    yield put(ordersDeleteOrderActions.failure());
  }
}

function* updateOrder({ payload }: AppAction<UpdateOrder>) {
  const resp: ExtendedAxiosResponse = yield call(api.update, payload);
  if (resp.ok) {
    yield put(ordersUpdateOrderActions.success(resp.data));
    yield put(ordersGetOrderActions.request(payload.id));
  } else {
    yield put(ordersUpdateOrderActions.failure());
    toast.error('Pri aktualizácii objednávky sa vyskytla chyba');
  }
}

function* createConcept({ payload }: AppAction<CreateConceptActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.createConcept, payload.concept);
  if (resp.ok) {
    yield put(ordersCreateConceptActions.success());
  } else {
    yield put(ordersCreateConceptActions.failure());
  }
}

function* createPlace({ payload }: AppAction<OrderPlace>) {
  const createPlaceRes: ExtendedAxiosResponse = yield call(api.createPlace, payload.orderId, {
    placeId: payload.residence,
  });
  if (createPlaceRes.ok) {
    yield put(ordersCreatePlaceActions.success(createPlaceRes.data));
    const getPlacesRes: ExtendedAxiosResponse = yield call(
      api.getPlaces,
      createPlaceRes.data.orderId
    );

    if (getPlacesRes.ok) {
      const getOrderRes: ExtendedAxiosResponse = yield call(api.get, createPlaceRes.data.orderId);

      if (getOrderRes.ok) {
        const places = getPlacesRes.data.data
          .map((entry: { place: OrderPlace }) => entry.place)
          .filter((place: OrderPlace) => place);

        yield put(
          ordersGetOrderActions.success({
            ...getOrderRes.data,
            places: places,
          })
        );
        toast.success('Uložené');
        return;
      }
    }
  }

  toast.error('Vyskytla sa chyba');
  yield put(ordersCreatePlaceActions.failure());
}

function* deletePlace({ payload }: AppAction<OrderPlace>) {
  const deletePlaceRes: ExtendedAxiosResponse = yield call(api.deletePlace, payload);
  if (deletePlaceRes.ok) {
    yield put(ordersDeletePlaceActions.success(deletePlaceRes.data));
    const getPlacesRes: ExtendedAxiosResponse = yield call(
      api.getPlaces,
      deletePlaceRes.data.orderId
    );

    if (getPlacesRes.ok) {
      const getOrderRes: ExtendedAxiosResponse = yield call(api.get, deletePlaceRes.data.orderId);

      if (getOrderRes.ok) {
        const places = getPlacesRes.data.data
          .map((entry: { place: OrderPlace }) => entry.place)
          .filter((place: OrderPlace) => place);

        yield put(
          ordersGetOrderActions.success({
            ...getOrderRes.data,
            places: places,
          })
        );
        toast.success('Uložené');
        return;
      }
    }
  }

  toast.error('Vyskytla sa chyba');
  yield put(ordersDeletePlaceActions.failure());
}

function* createUser({ payload }: AppAction<OrderUserActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.createUser, payload.orderId, payload.userId);
  if (resp.ok) {
    toast.success('Používateľ pridaný');
  } else {
    toast.error('Pri pridávaní používateľa sa vyskytla chyba');
  }
}

function* deleteUser({ payload }: AppAction<OrderUserActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteUser, payload.orderId, payload.userId);
  if (resp.ok) {
    toast.success('Používateľ odobraný');
  } else {
    toast.error('Pri odoberaní používateľa sa vyskytla chyba');
  }
}

function* setFilter({ payload }: AppAction<OrderFilter>) {
  const resp: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, 0, payload);
  if (resp.ok) {
    yield put(ordersGetOrdersActions.success(resp.data));
  } else {
    yield put(ordersGetOrdersActions.failure());
  }
}

function* sendEmail({ payload }: AppAction<OrderNotification>) {
  const uploading = toast.loading('Email sa odosiela');
  const resp: ExtendedAxiosResponse = yield call(api.sendEmail, payload);
  if (resp.ok) {
    toast.update(uploading, {
      render: 'Email bol úspešne odoslaný',
      type: 'success',
      isLoading: false,
      autoClose: 3000,
    });
    yield put(orderNotificationActions.success(resp.data));
  } else {
    toast.update(uploading, {
      render: 'Pri odosielaní emailu sa vyskytla chyba',
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    });
    console.error(resp.data.errors);

    yield put(orderNotificationActions.failure());
  }
}

/* EXPORT */
export function* ordersSaga() {
  yield takeLatest(
    createActionType(OrdersActionTypes.GetOrders, RequestActionTypes.REQUEST),
    getOrders
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.GetOrder, RequestActionTypes.REQUEST),
    getOrder
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.CreateOrder, RequestActionTypes.REQUEST),
    createOrder
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.DeleteOrder, RequestActionTypes.REQUEST),
    deleteOrder
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.UpdateOrder, RequestActionTypes.REQUEST),
    updateOrder
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.CreateConcept, RequestActionTypes.REQUEST),
    createConcept
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.CreatePlace, RequestActionTypes.REQUEST),
    createPlace
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.DeletePlace, RequestActionTypes.REQUEST),
    deletePlace
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.CreateUser, RequestActionTypes.REQUEST),
    createUser
  );
  yield takeLatest(
    createActionType(OrdersActionTypes.DeleteUser, RequestActionTypes.REQUEST),
    deleteUser
  );
  yield takeLatest(OrdersActionTypes.SetFilter, setFilter);
  yield takeLatest(
    createActionType(OrdersActionTypes.Notify, RequestActionTypes.REQUEST),
    sendEmail
  );
}
