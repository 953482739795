import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { DeviceRoutes } from '../../helpers/route/routes/device-routes';
import { history } from '../../helpers/store/root-reducer';
import {
  Device,
  DeviceFilter,
  DeviceResponse,
  CreateDevice,
  OrderDevice,
} from '../../models/Device';
import { OrderPlace } from '../../models/Order';
import { customersGetCustomerActions } from '../customers/actions';
import { api as customerApi } from '../customers/api';
import { ordersGetOrderActions } from '../orders/actions';
import {
  DeleteDeviceActions,
  OrderDeviceActions,
  DevicesActionTypes,
  devicesCreateDeviceActions,
  devicesDeleteDeviceActions,
  devicesGetDeviceActions,
  devicesGetDevicesActions,
  devicesGetOrderDevicesActions,
  devicesUpdateDeviceActions,
  GetDevicesActions,
  UpdateCheckingActions,
  devicesGetCustomerDevicesActions,
} from './actions';
import { api } from './api';
import { selectDeviceFilter } from './selectors';
const ITEMS_PER_PAGE = 50;

/* STATE */
export interface DevicesState {
  deviceResponse: DeviceResponse;
  selectedDevice: Device | null;
  selectedOrderDevices: OrderDevice[] | null;
  selectedCustomerDevices: Device[] | null;
  loading: LoadingStatus;
  deviceFilter: DeviceFilter;
}

/* REDUCERS */
const initialState: DevicesState = {
  deviceResponse: {
    limit: 0,
    total: 0,
    skip: 0,
    data: [],
  },
  deviceFilter: {},
  selectedDevice: null,
  selectedOrderDevices: null,
  selectedCustomerDevices: null,
  loading: LoadingStatus.initial,
};

const deviceResponse = createReducer(initialState.deviceResponse, {
  [DevicesActionTypes.GetDevices]: {
    [RequestActionTypes.REQUEST]: initialState.deviceResponse,
    [RequestActionTypes.SUCCESS]: (state: DeviceResponse, payload: DeviceResponse) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.deviceResponse,
  },
  [DevicesActionTypes.UpdateDevice]: {
    [RequestActionTypes.SUCCESS]: (state: Device[], payload: Device) => [
      ...state.filter((device: Device) => device.id !== payload.id),
      payload,
    ],
  },
  [DevicesActionTypes.DeleteDevice]: {
    [RequestActionTypes.SUCCESS]: (state: Device[], payload: string) => [
      ...state.filter((device: Device) => device.id !== payload),
    ],
  },
});

const selectedDevice = createReducer(initialState.selectedDevice, {
  [DevicesActionTypes.GetDevice]: {
    [RequestActionTypes.SUCCESS]: (state: Device | null, payload: Device) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedDevice,
  },
  [DevicesActionTypes.CleanSelectedDevice]: () => initialState.selectedDevice,
});

const selectedOrderDevices = createReducer(initialState.selectedOrderDevices, {
  [DevicesActionTypes.GetOrderDevices]: {
    [RequestActionTypes.SUCCESS]: (state: OrderDevice | null, payload: OrderDevice) => payload,
  },
});

const selectedCustomerDevices = createReducer(initialState.selectedCustomerDevices, {
  [DevicesActionTypes.GetCustomerDevices]: {
    [RequestActionTypes.SUCCESS]: (state: Device | null, payload: Device) => payload,
  },
});

const deviceFilter = createReducer(initialState.deviceFilter, {
  [DevicesActionTypes.SetFilter]: (state: DeviceFilter, payload: DeviceFilter) => payload,
});

const loading = createLoadingStateReducer(initialState.loading, {
  [DevicesActionTypes.CreateDevice]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DevicesActionTypes.GetDevices]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DevicesActionTypes.GetDevice]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

export default combineReducers<DevicesState>({
  deviceResponse,
  deviceFilter,
  selectedDevice,
  selectedOrderDevices,
  selectedCustomerDevices,
  loading,
});

/* SAGAS */
function* getDevices({ payload }: AppAction<GetDevicesActions>) {
  const selectFilter: DeviceFilter = yield select(selectDeviceFilter);
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    payload?.limit,
    payload?.skip,
    selectFilter
  );
  if (resp.ok) {
    yield put(devicesGetDevicesActions.success(resp.data));
  } else {
    yield put(devicesGetDevicesActions.failure());
  }
}

function* getDevice({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.get, payload);
  if (resp.ok) {
    yield put(devicesGetDeviceActions.success(resp.data));
  } else {
    yield put(devicesGetDeviceActions.failure());
  }
}

function* createDevice({ payload }: AppAction<CreateDevice>) {
  const { disableRedirectOnCreate, fetchCustomerDevicesOnCreate, ...device } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.create, device);
  if (resp.ok) {
    const newOrderId = resp.data.id;
    const res: ExtendedAxiosResponse = yield call(api.get, newOrderId);
    if (res.ok) {
      yield put(devicesGetDeviceActions.success(res.data));
    }
    if (payload.customerId != undefined) {
      if (fetchCustomerDevicesOnCreate) {
        yield put(devicesGetCustomerDevicesActions.request(payload.customerId));
      }

      const re: ExtendedAxiosResponse = yield call(api.getAll, 50, 0, {
        customerId: payload.customerId,
      } as DeviceFilter);
      if (re.ok) {
        yield put(devicesGetDevicesActions.success(re.data));
      }
      const r: ExtendedAxiosResponse = yield call(customerApi.get, payload.customerId);
      if (r.ok) {
        const customerPlacesResp: ExtendedAxiosResponse = yield call(
          customerApi.getPlaces,
          payload.customerId
        );
        if (customerPlacesResp.ok) {
          yield put(
            customersGetCustomerActions.success({
              ...r.data,
              places: customerPlacesResp.data.data,
            })
          );
          if (!disableRedirectOnCreate) {
            history.push(
              buildRoute([AppRoutes.Devices, DeviceRoutes.DeviceDetails], {
                deviceId: newOrderId,
              })
            );
          }
        }
      }
    }
  } else {
    yield put(devicesCreateDeviceActions.failure());
  }
}

function* createOrderDevice({ payload }: AppAction<OrderDeviceActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.createOrderDevice, payload.orderId, {
    deviceId: payload.id,
  });
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.getOrder, payload.orderId);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(api.getOrderPlaces, payload.orderId);
      if (re.ok) {
        const places = re.data.data
          .map((entry: { place: OrderPlace }) => entry.place)
          .filter((place: OrderPlace) => place);

        yield put(
          ordersGetOrderActions.success({
            ...res.data,
            places: places,
          })
        );
      }
    }
    const re: ExtendedAxiosResponse = yield call(api.get, payload.id);
    if (re.ok) {
      yield put(devicesGetDeviceActions.success(re.data));
    }
  }
}

function* deleteDevice({ payload }: AppAction<DeleteDeviceActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.delete, payload.id);
  if (resp.ok) {
    const selectFilter: DeviceFilter = yield select(selectDeviceFilter);
    const res: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, 0, selectFilter);
    if (res.ok) {
      yield put(devicesGetDevicesActions.success(res.data));
    }
  } else {
    yield put(devicesDeleteDeviceActions.failure());
  }
}

function* deleteOrderDevice({ payload }: AppAction<OrderDeviceActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteOrderDevice, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.getOrder, payload.orderId);
    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(api.getOrderPlaces, payload.orderId);
      if (re.ok) {
        const places = re.data.data
          .map((entry: { place: OrderPlace }) => entry.place)
          .filter((place: OrderPlace) => place);

        yield put(
          ordersGetOrderActions.success({
            ...res.data,
            places: places,
          })
        );
      }
    }
    const re: ExtendedAxiosResponse = yield call(api.get, payload.id);
    if (re.ok) {
      yield put(devicesGetDeviceActions.success(re.data));
    }
  }
}

function* deleteCustomerDevice({ payload }: AppAction<OrderDeviceActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteCustomerDevice, payload.id, {
    customerId: null,
    placeId: null,
  });
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(customerApi.get, payload.orderId);
    yield put(devicesGetCustomerDevicesActions.request(payload.orderId));

    if (res.ok) {
      const re: ExtendedAxiosResponse = yield call(customerApi.getPlaces, payload.orderId);
      if (re.ok) {
        yield put(
          customersGetCustomerActions.success({
            ...res.data,
            places: re.data.data,
          })
        );
      }
    }
    const re: ExtendedAxiosResponse = yield call(api.get, payload.id);
    if (re.ok) {
      yield put(devicesGetDeviceActions.success(re.data));
    }
  }
}

function* updateDevice({ payload }: AppAction<Device>) {
  const { id, ...device } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.update, id, device as Device);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.get, payload.id);
    if (res.ok) {
      yield put(devicesGetDeviceActions.success(res.data));
    }
  } else {
    yield put(devicesUpdateDeviceActions.failure());
  }
}

function* getOrderDevices({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrderDevices, payload);
  if (resp.ok) {
    yield put(devicesGetOrderDevicesActions.success(resp.data.data));
  } else {
    yield put(devicesGetOrderDevicesActions.failure());
  }
}

function* getCustomerDevices({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.getCustomerDevices, payload);
  if (resp.ok) {
    yield put(devicesGetCustomerDevicesActions.success(resp.data));
  } else {
    yield put(devicesGetCustomerDevicesActions.failure());
  }
}

function* updateChecking({ payload }: AppAction<UpdateCheckingActions>) {
  const { id, ...device } = payload;
  const resp: ExtendedAxiosResponse = yield call(
    api.updateChecking,
    id,
    device as UpdateCheckingActions
  );
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.get, payload.id);
    if (res.ok) {
      yield put(devicesGetDeviceActions.success(res.data));
    }
    const selectFilter: DeviceFilter = yield select(selectDeviceFilter);
    const re: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, 0, selectFilter);
    if (re.ok) {
      yield put(devicesGetDevicesActions.success(re.data));
    }
  } else {
    yield put(devicesUpdateDeviceActions.failure());
  }
}

function* setFilter({ payload }: AppAction<GetDevicesActions>) {
  const resp: ExtendedAxiosResponse = yield call(
    api.getAll,
    ITEMS_PER_PAGE,
    0,
    payload as DeviceFilter
  );
  if (resp.ok) {
    yield put(devicesGetDevicesActions.success(resp.data));
  } else {
    yield put(devicesGetDevicesActions.failure());
  }
}

/* EXPORT */
export function* devicesSaga() {
  yield takeLatest(
    createActionType(DevicesActionTypes.GetDevices, RequestActionTypes.REQUEST),
    getDevices
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.GetDevice, RequestActionTypes.REQUEST),
    getDevice
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.CreateDevice, RequestActionTypes.REQUEST),
    createDevice
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.CreateOrderDevice, RequestActionTypes.REQUEST),
    createOrderDevice
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.DeleteDevice, RequestActionTypes.REQUEST),
    deleteDevice
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.DeleteOrderDevice, RequestActionTypes.REQUEST),
    deleteOrderDevice
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.DeleteCustomerDevice, RequestActionTypes.REQUEST),
    deleteCustomerDevice
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.UpdateDevice, RequestActionTypes.REQUEST),
    updateDevice
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.GetOrderDevices, RequestActionTypes.REQUEST),
    getOrderDevices
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.GetCustomerDevices, RequestActionTypes.REQUEST),
    getCustomerDevices
  );
  yield takeLatest(
    createActionType(DevicesActionTypes.UpdateChecking, RequestActionTypes.REQUEST),
    updateChecking
  );
  yield takeLatest(DevicesActionTypes.SetFilter, setFilter);
}
