import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import {
  DeviceTypeSalesStatFilter,
  OrderStateStatFilter,
  OrderTypeSalesStatFilter,
  OrderTypeStatFilter,
  StatFilter,
} from '../../models/Stats';

export enum StatsActionTypes {
  GetOrderStateStat = '@@/Stats/GET_ORDER_STATE_STAT',
  GetOrderTypeStat = '@@/Stats/GET_ORDER_TYPE_STAT',
  GetOrderSellerStat = '@@/Stats/GET_ORDER_SELLER_STAT',
  GetOrderDepartmentSalesStat = '@@/Stats/GET_DEPARTMENT_SALES_STAT',
  GetOrderTypeSalesStat = '@@/Stats/GET_ORDER_TYPE_SALES_STAT',
  GetDeviceTypeSalesStat = '@@/Stats/GET_DEVICE_TYPE_SALES_STAT',
  SetOrderStateStatFilter = '@@/Stats/SET_ORDER_STATE_FILTER',
  SetOrderTypeStatFilter = '@@/Stats/SET_ORDER_TYPE_FILTER',
  SetOrderSellerStatFilter = '@@/Stats/SET_ORDER_SELLER_FILTER',
  SetOrderDepartmentSalesStatFilter = '@@/Stats/SET_DEPARTMENT_SALES_FILTER',
  SetOrderTypeSalesStatFilter = '@@/Stats/SET_ORDER_TYPE_SALES_FILTER',
  SetDeviceTypeSalesStatFilter = '@@/Stats/SET_DEVICE_TYPE_SALES_FILTER',
}

export const statsGetOrderStateStatActions = createApiActionCreators<OrderStateStatFilter>(
  StatsActionTypes.GetOrderStateStat
);
export const statsGetOrderTypeStatActions = createApiActionCreators<OrderTypeStatFilter>(
  StatsActionTypes.GetOrderTypeStat
);
export const statsGetOrderSellerStatActions = createApiActionCreators<StatFilter>(
  StatsActionTypes.GetOrderSellerStat
);
export const statsGetOrderDepartmentSalesStatActions = createApiActionCreators<StatFilter>(
  StatsActionTypes.GetOrderDepartmentSalesStat
);
export const statsGetOrderTypeSalesStatActions = createApiActionCreators<OrderTypeSalesStatFilter>(
  StatsActionTypes.GetOrderTypeSalesStat
);
export const statsGetDeviceTypeSalesStatActions =
  createApiActionCreators<DeviceTypeSalesStatFilter>(StatsActionTypes.GetDeviceTypeSalesStat);

export const statsSetOrderStateStatFilterActions = createActionCreator(
  StatsActionTypes.SetOrderStateStatFilter
);
export const statsSetOrderTypeStatFilterActions = createActionCreator(
  StatsActionTypes.SetOrderTypeStatFilter
);
export const statsSetOrderSellerStatFilterActions = createActionCreator(
  StatsActionTypes.SetOrderSellerStatFilter
);
export const statsSetOrderDepartmentSalesStatFilterActions = createActionCreator(
  StatsActionTypes.SetOrderDepartmentSalesStatFilter
);
export const statsSetOrderTypeSalesStatFilterActions = createActionCreator(
  StatsActionTypes.SetOrderTypeSalesStatFilter
);
export const statsSetDeviceTypeSalesStatFilterActions = createActionCreator(
  StatsActionTypes.SetDeviceTypeSalesStatFilter
);
