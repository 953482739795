import { AppState } from '../../helpers/store/AppState';

const selectUsers = (state: AppState) => state.users;

export const selectUsersList = (state: AppState) => selectUsers(state).userResponse.data;

export const selectUsersLoading = (state: AppState) => selectUsers(state).loading;

export const selectSelectedUser = (state: AppState) => selectUsers(state).selectedUser;

export const selectUsersTotal = (state: AppState) => state.users.userResponse.total;

export const selectUsersFilter = (state: AppState) => state.users.userFilter;
