import React from 'react';

import { AppRoute } from '../Route';

export enum DepartureRoutes {
  Departures = '',
  DepartureDetails = ':departureId',
  NewDeparture = 'newDeparture/:orderId',
  NewReport = 'newReport/:departureId',
}

const departureRoutes: AppRoute[] = [
  {
    path: DepartureRoutes.Departures,
    exact: true,
    authenticated: true,
    component: React.lazy(
      () => import('../../../views/pages/Departures/DeparturesList/DeparturesList')
    ),
  },
  {
    path: DepartureRoutes.DepartureDetails,
    exact: true,
    authenticated: true,
    component: React.lazy(
      () => import('../../../views/pages/Departures/DepartureDetails/DepartureDetails')
    ),
  },
  {
    path: DepartureRoutes.NewDeparture,
    exact: true,
    authenticated: true,
    component: React.lazy(
      () => import('../../../views/pages/Departures/NewDeparture/NewDeparture')
    ),
  },
  {
    path: DepartureRoutes.NewReport,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Departures/NewReport/NewReport')),
  },
];

export default departureRoutes;
