import { toast } from 'react-toastify';
import { combineReducers } from 'redux';

import { call, put, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import {
  OrderDepartmentSalesStat,
  OrderSellerStat,
  OrderStateStat,
  OrderStateStatFilter,
  OrderTypeSalesStatFilter,
  OrderTypeSalesStat,
  OrderTypeStat,
  OrderTypeStatFilter,
  StatFilter,
  DeviceTypeSalesStat,
  DeviceTypeSalesStatFilter,
} from '../../models/Stats';
import {
  StatsActionTypes,
  statsGetDeviceTypeSalesStatActions,
  statsGetOrderDepartmentSalesStatActions,
  statsGetOrderSellerStatActions,
  statsGetOrderStateStatActions,
  statsGetOrderTypeSalesStatActions,
  statsGetOrderTypeStatActions,
} from './actions';

import { api } from './api';

/* STATE */
export interface StatsState {
  loading: LoadingStatus;
  orderStateStat: OrderStateStat[] | null;
  orderTypeStat: OrderTypeStat[] | null;
  orderSellerStat: OrderSellerStat[] | null;
  orderDepartmentSalesStat: OrderDepartmentSalesStat[] | null;
  orderTypeSalesStat: OrderTypeSalesStat[] | null;
  deviceTypeSalesStat: DeviceTypeSalesStat[] | null;
  orderStateStatFilter: OrderStateStatFilter;
  orderTypeStatFilter: OrderTypeStatFilter;
  orderSellerStatFilter: StatFilter;
  orderDepartmentSalesStatFilter: StatFilter;
  orderTypeSalesStatFilter: OrderTypeSalesStatFilter;
  deviceTypeSalesStatFilter: DeviceTypeSalesStatFilter;
}

/* REDUCERS */
const initialState: StatsState = {
  loading: LoadingStatus.initial,
  orderStateStat: null,
  orderTypeStat: null,
  orderSellerStat: null,
  orderDepartmentSalesStat: null,
  orderTypeSalesStat: null,
  deviceTypeSalesStat: null,
  orderStateStatFilter: {},
  orderTypeStatFilter: {},
  orderSellerStatFilter: {},
  orderDepartmentSalesStatFilter: {},
  orderTypeSalesStatFilter: {},
  deviceTypeSalesStatFilter: {},
};

const orderStateStat = createReducer(initialState.orderStateStat, {
  [StatsActionTypes.GetOrderStateStat]: {
    [RequestActionTypes.REQUEST]: initialState.orderStateStat,
    [RequestActionTypes.SUCCESS]: (state: OrderStateStat, payload: OrderStateStat) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.orderStateStat,
  },
});

const orderTypeStat = createReducer(initialState.orderTypeStat, {
  [StatsActionTypes.GetOrderTypeStat]: {
    [RequestActionTypes.REQUEST]: initialState.orderTypeStat,
    [RequestActionTypes.SUCCESS]: (state: OrderStateStat, payload: OrderTypeStat) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.orderTypeStat,
  },
});

const orderSellerStat = createReducer(initialState.orderSellerStat, {
  [StatsActionTypes.GetOrderSellerStat]: {
    [RequestActionTypes.REQUEST]: initialState.orderSellerStat,
    [RequestActionTypes.SUCCESS]: (state: OrderSellerStat, payload: OrderSellerStat) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.orderSellerStat,
  },
});

const orderDepartmentSalesStat = createReducer(initialState.orderDepartmentSalesStat, {
  [StatsActionTypes.GetOrderDepartmentSalesStat]: {
    [RequestActionTypes.REQUEST]: initialState.orderDepartmentSalesStat,
    [RequestActionTypes.SUCCESS]: (
      state: OrderDepartmentSalesStat,
      payload: OrderDepartmentSalesStat
    ) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.orderSellerStat,
  },
});

const deviceTypeSalesStat = createReducer(initialState.deviceTypeSalesStat, {
  [StatsActionTypes.GetDeviceTypeSalesStat]: {
    [RequestActionTypes.REQUEST]: initialState.deviceTypeSalesStat,
    [RequestActionTypes.SUCCESS]: (state: DeviceTypeSalesStat, payload: DeviceTypeSalesStat) =>
      payload,
    [RequestActionTypes.FAILURE]: () => initialState.deviceTypeSalesStat,
  },
});

const orderTypeSalesStat = createReducer(initialState.orderTypeSalesStat, {
  [StatsActionTypes.GetOrderTypeSalesStat]: {
    [RequestActionTypes.REQUEST]: initialState.orderTypeSalesStat,
    [RequestActionTypes.SUCCESS]: (state: OrderTypeSalesStat, payload: OrderTypeSalesStat) =>
      payload,
    [RequestActionTypes.FAILURE]: () => initialState.orderTypeSalesStat,
  },
});

const loading = createLoadingStateReducer(initialState.loading, {
  [StatsActionTypes.GetOrderStateStat]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [StatsActionTypes.GetOrderTypeStat]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [StatsActionTypes.GetOrderSellerStat]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [StatsActionTypes.GetOrderDepartmentSalesStat]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [StatsActionTypes.GetOrderTypeSalesStat]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [StatsActionTypes.GetDeviceTypeSalesStat]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const orderStateStatFilter = createReducer(initialState.orderStateStatFilter, {
  [StatsActionTypes.SetOrderStateStatFilter]: (
    state: OrderStateStatFilter,
    payload: OrderStateStatFilter
  ) => payload,
});

const orderTypeStatFilter = createReducer(initialState.orderTypeStatFilter, {
  [StatsActionTypes.SetOrderTypeStatFilter]: (
    state: OrderTypeStatFilter,
    payload: OrderTypeStatFilter
  ) => payload,
});

const orderSellerStatFilter = createReducer(initialState.orderSellerStatFilter, {
  [StatsActionTypes.SetOrderSellerStatFilter]: (state: StatFilter, payload: StatFilter) => payload,
});

const orderDepartmentSalesStatFilter = createReducer(initialState.orderDepartmentSalesStatFilter, {
  [StatsActionTypes.SetOrderDepartmentSalesStatFilter]: (state: StatFilter, payload: StatFilter) =>
    payload,
});

const orderTypeSalesStatFilter = createReducer(initialState.orderTypeSalesStatFilter, {
  [StatsActionTypes.SetOrderTypeSalesStatFilter]: (
    state: OrderTypeSalesStatFilter,
    payload: OrderTypeSalesStatFilter
  ) => payload,
});

const deviceTypeSalesStatFilter = createReducer(initialState.deviceTypeSalesStatFilter, {
  [StatsActionTypes.SetDeviceTypeSalesStatFilter]: (
    state: DeviceTypeSalesStatFilter,
    payload: DeviceTypeSalesStatFilter
  ) => payload,
});

export default combineReducers<StatsState>({
  orderStateStat,
  orderTypeStat,
  orderSellerStat,
  orderDepartmentSalesStat,
  orderTypeSalesStat,
  deviceTypeSalesStat,
  loading,
  orderStateStatFilter,
  orderTypeStatFilter,
  orderSellerStatFilter,
  orderDepartmentSalesStatFilter,
  orderTypeSalesStatFilter,
  deviceTypeSalesStatFilter,
});

/* SAGAS */

function* getOrderStateStat({ payload }: AppAction<OrderStateStatFilter>) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrderStateStat, payload);

  if (resp.ok) {
    yield put(statsGetOrderStateStatActions.success(resp.data));
  } else {
    yield put(statsGetOrderStateStatActions.failure());
    toast.error('Nepodarilo sa načítať štatistiku');
  }
}

function* getOrderTypeStat({ payload }: AppAction<OrderTypeStatFilter>) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrderTypeStat, payload);

  if (resp.ok) {
    yield put(statsGetOrderTypeStatActions.success(resp.data));
  } else {
    yield put(statsGetOrderTypeStatActions.failure());
    toast.error('Nepodarilo sa načítať štatistiku');
  }
}

function* getOrderSellerStat({ payload }: AppAction<StatFilter>) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrderSellerStat, payload);

  if (resp.ok) {
    yield put(statsGetOrderSellerStatActions.success(resp.data));
  } else {
    yield put(statsGetOrderSellerStatActions.failure());
    toast.error('Nepodarilo sa načítať štatistiku');
  }
}

function* getOrderDepartmentSalesStat({ payload }: AppAction<StatFilter>) {
  const resp: ExtendedAxiosResponse = yield call(api.getDepartmentSalesStat, payload);

  if (resp.ok) {
    yield put(statsGetOrderDepartmentSalesStatActions.success(resp.data));
  } else {
    yield put(statsGetOrderDepartmentSalesStatActions.failure());
    toast.error('Nepodarilo sa načítať štatistiku');
  }
}

function* getOrderTypeSalesStat({ payload }: AppAction<OrderTypeSalesStatFilter>) {
  if (payload.departmentResidence === 'all') {
    payload.departmentResidence = undefined;
  }

  const resp: ExtendedAxiosResponse = yield call(api.getOrderTypeSalesStat, payload);

  if (resp.ok) {
    yield put(statsGetOrderTypeSalesStatActions.success(resp.data));
  } else {
    yield put(statsGetOrderTypeSalesStatActions.failure());
    toast.error('Nepodarilo sa načítať štatistiku');
  }
}

function* getDeviceTypeSalesStat({ payload }: AppAction<DeviceTypeSalesStatFilter>) {
  if (payload.departmentResidence === 'all') {
    payload.departmentResidence = undefined;
  }

  const resp: ExtendedAxiosResponse = yield call(api.getDeviceTypeSalesStat, payload);

  if (resp.ok) {
    yield put(statsGetDeviceTypeSalesStatActions.success(resp.data));
  } else {
    yield put(statsGetDeviceTypeSalesStatActions.failure());
    toast.error('Nepodarilo sa načítať štatistiku');
  }
}

/* EXPORT */
export function* statsSaga() {
  yield takeLatest(
    createActionType(StatsActionTypes.GetOrderStateStat, RequestActionTypes.REQUEST),
    getOrderStateStat
  );
  yield takeLatest(
    createActionType(StatsActionTypes.GetOrderTypeStat, RequestActionTypes.REQUEST),
    getOrderTypeStat
  );
  yield takeLatest(
    createActionType(StatsActionTypes.GetOrderSellerStat, RequestActionTypes.REQUEST),
    getOrderSellerStat
  );
  yield takeLatest(
    createActionType(StatsActionTypes.GetOrderDepartmentSalesStat, RequestActionTypes.REQUEST),
    getOrderDepartmentSalesStat
  );
  yield takeLatest(
    createActionType(StatsActionTypes.GetOrderTypeSalesStat, RequestActionTypes.REQUEST),
    getOrderTypeSalesStat
  );
  yield takeLatest(
    createActionType(StatsActionTypes.GetDeviceTypeSalesStat, RequestActionTypes.REQUEST),
    getDeviceTypeSalesStat
  );
}
