import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { toastClearAction } from '../../../saga/toast/actions';
import { selectToastState } from '../../../saga/toast/selectors';
import { Translate } from '../Translate/Translate';

const ToastMessages = () => {
  const dispatch = useDispatch();
  const { message, type } = useSelector(selectToastState);

  useEffect(() => {
    if (message && type) {
      toast(<Translate>{message}</Translate>, { type });

      dispatch(toastClearAction());
    }
  }, [message, type]);

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
    />
  );
};

export default ToastMessages;
