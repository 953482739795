import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { Device, CreateDevice, DeviceFilter } from '../../models/Device';

/* ACTION TYPES */
export enum DevicesActionTypes {
  CreateDevice = '@@Devices/CREATE_DEVICE',
  GetDevices = '@@Devices/GET_DEVICES',
  GetDevice = '@@Devices/GET_DEVICE',
  DeleteDevice = '@@Devices/DELETE_DEVICE',
  CreateOrderDevice = '@@Devices/CREATE_ORDER_DEVICE',
  DeleteOrderDevice = '@@Devices/DELETE_ORDER_DEVICE',
  DeleteCustomerDevice = '@@Devices/DELETE_CUSTOMER_DEVICE',
  UpdateDevice = '@@Devices/UPDATE_DEVICE',
  UpdateChecking = '@@Devices/UPDATE_CHECKING',
  CleanSelectedDevice = '@@Devices/CLEAN_SELECTED_DEVICE',
  SetFilter = '@@Devices/SET_FILTER',
  GetOrderDevices = '@@Devices/GET_ORDER_DEVICES',
  GetCustomerDevices = '@@Devices/GET_CUSTOMER_DEVICES',
}

/* ACTION MODELS */
export interface CreateDeviceActions {
  device: CreateDevice;
}

export interface GetDeviceActions {
  id: string;
  devices: Device[];
}

export interface DeleteDeviceActions {
  id: string;
}

export interface OrderDeviceActions {
  id: string;
  orderId: string;
}

export interface UpdateDeviceActions {
  updatedDevice: Device;
}

export interface UpdateCheckingActions {
  id: string;
  ids: string[];
  date: string;
}

export interface GetDevicesActions {
  filter?: DeviceFilter;
  limit: number;
  skip: number;
}

/* ACTIONS */
export const devicesCreateDeviceActions = createApiActionCreators<CreateDevice>(
  DevicesActionTypes.CreateDevice
);
export const devicesCreateOrderDeviceActions = createApiActionCreators<OrderDeviceActions>(
  DevicesActionTypes.CreateOrderDevice
);
export const devicesDeleteDeviceActions = createApiActionCreators<DeleteDeviceActions>(
  DevicesActionTypes.DeleteDevice
);
export const devicesDeleteOrderDeviceActions = createApiActionCreators<OrderDeviceActions>(
  DevicesActionTypes.DeleteOrderDevice
);
export const devicesDeleteCustomerDeviceActions = createApiActionCreators<OrderDeviceActions>(
  DevicesActionTypes.DeleteCustomerDevice
);
export const devicesUpdateDeviceActions = createApiActionCreators<Device>(
  DevicesActionTypes.UpdateDevice
);
export const devicesGetDevicesActions = createApiActionCreators(DevicesActionTypes.GetDevices);
export const devicesGetDeviceActions = createApiActionCreators<string>(
  DevicesActionTypes.GetDevice
);
export const devicesCleanSelectedDeviceActions = createActionCreator(
  DevicesActionTypes.CleanSelectedDevice
);
export const devicesGetOrderDevicesActions = createApiActionCreators<string>(
  DevicesActionTypes.GetOrderDevices
);
export const devicesGetCustomerDevicesActions = createApiActionCreators<string>(
  DevicesActionTypes.GetCustomerDevices
);
export const devicesSetFilterActions = createActionCreator(DevicesActionTypes.SetFilter);
export const devicesUpdateCheckingActions = createApiActionCreators<UpdateCheckingActions>(
  DevicesActionTypes.UpdateChecking
);
