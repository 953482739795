import { AppState } from '../../helpers/store/AppState';

export const selectDocuments = (state: AppState) => state.documents;

export const selectDocumentsList = (state: AppState) =>
  selectDocuments(state).documentResponse.data;

export const selectSelectedDocument = (state: AppState) => selectDocuments(state).selectedDocument;

export const selectDocumentsTotal = (state: AppState) => state.documents.documentResponse.total;

export const selectDocumentsFilter = (state: AppState) => state.documents.documentFilter;

export const selectDocumentsLoading = (state: AppState) => selectDocuments(state).loading;

export const selectDocumentsLastDocument = (state: AppState) => state.documents.lastDocument;
