import LoadingIcon from '../../../resources/images/Rolling-1s-39px.svg';
import { Translate } from '../Translate/Translate';

const Loading = () => (
  <div className="Loading">
    <img className="Loading__icon" src={LoadingIcon} alt="App Logo" />
    <div className="Loading__title">
      <Translate>Loading.Title</Translate>
    </div>
  </div>
);

export default Loading;
