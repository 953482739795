import React from 'react';

import { AppRoute } from '../Route';

export enum UserRoutes {
  Users = '',
  UserDetails = ':userId',
  CreateUser = 'newUser',
}

const userRoutes: AppRoute[] = [
  {
    path: UserRoutes.Users,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Users/UsersList/UsersList')),
  },
  {
    path: UserRoutes.CreateUser,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Users/NewUser/NewUser')),
  },
  {
    path: UserRoutes.UserDetails,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Users/UserDetails/UserDetails')),
  },
];

export default userRoutes;
