import { Language } from '../models/Language';
import { Environment } from './Type';

export const environment: Environment = {
  debug: true,
  production: false,
  name: 'development',

  i18n: {
    languages: [Language.Slovak],
    defaultLanguage: Language.Slovak,
    showEmptyKeys: true,
  },

  api: {
    protocol: 'http',
    host: 'localhost',
    port: 3000,
  },
};
