import { createActionCreator } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum LayoutActionTypes {
  SidebarShow = '@@Layout/SIDEBAR_SHOW',
}

/* ACTION MODELS */

/* ACTIONS */
export const layoutSidebarShowActions = createActionCreator(LayoutActionTypes.SidebarShow);
