import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import {
  CreateDeparture,
  Departure,
  DepartureFilter,
  UpdateDeparture,
  ViewMode,
} from '../../models/Departure';

/* ACTION TYPES */
export enum DeparturesActionTypes {
  CreateDeparture = '@@Departures/CREATE_DEPARTURE',
  CreatePlace = '@@Departures/CREATE_PLACE',
  CreateDevice = '@@Departures/CREATE_DEVICE',
  CreateUser = '@@Departures/CREATE_USER',
  DeletePlace = '@@Departures/DELETE_PLACE',
  DeleteDevice = '@@Departures/DELETE_DEVICE',
  DeleteUser = '@@Departures/DELETE_USER',
  GetDepartures = '@@Departures/GET_DEPARTURES',
  GetDeparture = '@@Departures/GET_DEPARTURE',
  DeleteDeparture = '@@Departures/DELETE_DEPARTURE',
  UpdateDeparture = '@@Departures/UPDATE_DEPARTURE',
  SelectDeparture = '@@Departures/SELECT_DEPARTURE',
  CleanDeparture = '@@Departures/CLEAN_DEPARTURE',
  SetFilter = '@@Departures/SET_FILTER',
  CleanFilter = '@@Departures/CLEAN_FILTER',
  SetCurrentPage = '@@Departures/SET_DEPARTURE_CURRENT_PAGE',
  CleanDepartures = '@@Departures/CLEAN_DEPARTURES',
  CleanSelectedDeparture = '@@Departures/CLEAN_SELECTED_DEVICE',
  SetViewMode = '@@Departures/SET_VIEW_MODE',
}

/* ACTION MODELS */
export interface CreateDepartureActions {
  departure: Departure;
}

export interface GetDepartureActions {
  id: string;
  departures: Departure[];
}

export interface DeleteDepartureActions {
  id: string;
  orderId?: string;
}

export interface SelectDepartureActions {
  selectedDeparture: Departure;
}

export interface UpdateDepartureActions {
  updatedDeparture: Departure;
}

export interface GetDeparturesActions {
  filter?: DepartureFilter;
  limit: number;
  skip: number;
}

export interface UpdateAllDepartureActions {
  id: string;
  value: string;
}

/* ACTIONS */
export const departuresCreateDepartureActions = createApiActionCreators<CreateDeparture>(
  DeparturesActionTypes.CreateDeparture
);
export const departuresCreatePlaceActions = createApiActionCreators<UpdateAllDepartureActions>(
  DeparturesActionTypes.CreatePlace
);
export const departuresCreateDeviceActions = createApiActionCreators<UpdateAllDepartureActions>(
  DeparturesActionTypes.CreateDevice
);
export const departuresCreateUserActions = createApiActionCreators<UpdateAllDepartureActions>(
  DeparturesActionTypes.CreateUser
);
export const departuresDeleteDepartureActions = createApiActionCreators<DeleteDepartureActions>(
  DeparturesActionTypes.DeleteDeparture
);
export const departuresDeletePlaceActions = createApiActionCreators<UpdateAllDepartureActions>(
  DeparturesActionTypes.DeletePlace
);
export const departuresDeleteDeviceActions = createApiActionCreators<UpdateAllDepartureActions>(
  DeparturesActionTypes.DeleteDevice
);
export const departuresDeleteUserActions = createApiActionCreators<UpdateAllDepartureActions>(
  DeparturesActionTypes.DeleteUser
);
export const departuresUpdateDepartureActions = createApiActionCreators<UpdateDeparture>(
  DeparturesActionTypes.UpdateDeparture
);
export const departuresGetDeparturesActions = createApiActionCreators(
  DeparturesActionTypes.GetDepartures
);
export const departuresGetDepartureActions = createApiActionCreators<string>(
  DeparturesActionTypes.GetDeparture
);
export const departuresSelectDepartureActions = createActionCreator<SelectDepartureActions>(
  DeparturesActionTypes.SelectDeparture
);
export const departuresCleanSelectedDepartureActions = createActionCreator(
  DeparturesActionTypes.CleanSelectedDeparture
);
export const departuresCleanDepartureActions = createActionCreator(
  DeparturesActionTypes.CleanDeparture
);
export const departuresCleanDeparturesActions = createActionCreator(
  DeparturesActionTypes.CleanDepartures
);
export const departuresSetCurrentPageActions = createActionCreator(
  DeparturesActionTypes.SetCurrentPage
);
export const departuresSetFilterActions = createActionCreator(DeparturesActionTypes.SetFilter);
export const departuresCleanFilterActions = createActionCreator(DeparturesActionTypes.CleanFilter);
export const departuresSetViewModeActions = createActionCreator<ViewMode>(
  DeparturesActionTypes.SetViewMode
);
