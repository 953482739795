/* eslint-disable camelcase */
import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  Order,
  OrderFilter,
  CreateOrder,
  UpdateOrder,
  OrderPlace,
  OrderNotification,
} from '../../models/Order';

export const api = {
  create: (order: CreateOrder): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/order/create-complete', order),
  createPlace: (id: string, placeId: any): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/order/${id}/place`, placeId),
  createUser: (orderId: string, userId: string): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/order/${orderId}/user`, { userId }),
  get: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/order/${id}`),
  getAll: ($limit: number, $skip: number, filter: OrderFilter): Promise<ExtendedAxiosResponse> => {
    const params: Record<string, any> = {
      $limit,
      $skip,
      '$sort[date]': filter?.sort ? filter?.sort : -1,
      'date_from': filter?.dateFrom,
      'date_to': filter?.dateTo,
      'update_date_from': filter?.updatedDateFrom,
      'update_date_to': filter?.updatedDateTo,
      'state': filter?.state,
      'company_name': filter?.companyName,
      'seller': filter?.sellerName,
      'id': filter?.id,
      'document': filter?.document,
      'department': filter?.departments,
      'intId': filter?.intId,
      'residence': filter?.residence,
      'user': filter?.user,
    };

    if (filter?.notState) {
      filter.notState.forEach((state, index) => {
        params[`state[$nin][${index}]`] = state;
      });
    }

    return apiClient.get('/order', { params });
  },
  getPlaces: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/order/${id}/place`),
  update: (updatedOrder: UpdateOrder): Promise<ExtendedAxiosResponse> => {
    const { id, ...order } = updatedOrder;
    return apiClient.put(`/order/${updatedOrder.id}`, order);
  },
  delete: (id: string): Promise<ExtendedAxiosResponse> => apiClient.delete(`/order/${id}`),
  deletePlace: (place: OrderPlace): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/order/${place.orderId}/place/${place.id}`),
  deleteUser: (orderId: string, userId: string): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/order/${orderId}/user/${userId}`),
  createConcept: (concept: Order): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/order/concept', {
      concept,
      strategy: 'local',
    }),
  sendEmail: (payload: OrderNotification) => apiClient.post('order/email', payload),
};
