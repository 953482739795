import { TypeOptions } from 'react-toastify/dist/types';
import { combineReducers } from 'redux';

import { createReducer } from '../../helpers/redux/redux-helpers';
import { ToastActionTypes } from './actions';

/* STATE */
export interface ToastState {
  message: string | null;
  type: TypeOptions | null;
}

/* REDUCERS */
const initialState: ToastState = {
  message: null,
  type: null,
};

const message = createReducer(initialState.message, {
  [ToastActionTypes.CreateToast]: (state: string | null, payload: ToastState) => payload.message,
  [ToastActionTypes.CreateSuccessToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.CreateWarnToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.CreateErrorToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.CreateInfoToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.ClearToast]: () => initialState.message,
});

const type = createReducer(initialState.type, {
  [ToastActionTypes.CreateToast]: (state: TypeOptions | null, payload: ToastState) => payload.type,
  [ToastActionTypes.CreateSuccessToast]: () => 'success',
  [ToastActionTypes.CreateWarnToast]: () => 'warning',
  [ToastActionTypes.CreateErrorToast]: () => 'error',
  [ToastActionTypes.CreateInfoToast]: () => 'info',
  [ToastActionTypes.ClearToast]: () => initialState.type,
});

export default combineReducers<ToastState>({
  message,
  type,
});

/* SAGAS */

/* EXPORT */
export function* toastSaga() {}
