import React from 'react';

import { AppRoute } from '../Route';

export enum OrderRoutes {
  Orders = '',
  NewOrder = 'newOrder',
  DetailOrder = ':orderId',
  NewConcept = 'newOrder/:type/:typeId',
  NewConceptDevice = 'newOrder/:type/:typeId',
}

const orderRoutes: AppRoute[] = [
  {
    path: OrderRoutes.Orders,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Orders/OrdersList/OrdersList')),
  },
  {
    path: OrderRoutes.NewOrder,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Orders/NewOrder/NewOrder')),
  },
  {
    path: OrderRoutes.DetailOrder,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Orders/OrderDetails/OrderDetails')),
  },
  {
    path: OrderRoutes.NewConcept,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Orders/NewOrder/NewOrder')),
  },
  {
    path: OrderRoutes.NewConceptDevice,
    exact: true,
    authenticated: true,
    component: React.lazy(() => import('../../../views/pages/Orders/NewOrder/NewOrder')),
  },
];

export default orderRoutes;
